import { Injectable } from "@angular/core";
import { DevOptionsS } from "../../services/dev-options";

// EMPTY SERVICE

@Injectable({
	providedIn: "root",
})
export class DevOptionsService extends DevOptionsS {
	constructor() {
		super();
	}

	setCurrentState() {}
}
