import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Agtuary
import { ContainerComponent } from "@src/app/modules/components/container/container.component";
import { LoginComponent } from "@pages/login/login.component";

// Customer

// Guards
import { AuthPublicGuard } from "@core/guards/auth-public.guard";

const userBasePath = "1";
const adminBasePath = "1";

const routes: Routes = [
	// Agtuary
	{ path: "login", component: LoginComponent },
	{ path: "login/:email", component: LoginComponent },
	{ path: "login/:email/:action", component: LoginComponent },

	// Customer
	// {
	// 	path: userBasePath,
	// 	component: ContainerComponent,
	// 	canActivate: [AuthPublicGuard],
	// 	children: [
	// 		{
	// 			path: "portfolio",
	// 			component: PortfolioComponent,
	// 			canActivate: [AuthPublicGuard],
	// 		},
	// 		{
	// 			path: "jobs",
	// 			component: JobsComponent,
	// 			canActivate: [AuthPublicGuard],
	// 		},
	// 		{
	// 			path: "board",
	// 			component: TaskboardComponent,
	// 			canActivate: [AuthPublicGuard],
	// 		},
	// 		// {
	// 		// 	path: "clients",
	// 		// 	component: ClientsComponent,
	// 		// 	canActivate: [AuthPublicGuard],
	// 		// },
	// 		{
	// 			path: "notifications",
	// 			component: NotificationsComponent,
	// 			canActivate: [AuthPublicGuard],
	// 		},

	// 		{
	// 			path: "region",
	// 			component: MacroComponent,
	// 			canActivate: [AuthPublicGuard],
	// 		},
	// 		{
	// 			path: "map",
	// 			component: CropMapComponent,
	// 			canActivate: [AuthPublicGuard],
	// 		},
	// 		{
	// 			path: "settings",
	// 			component: SettingsComponent,
	// 			canActivate: [AuthPublicGuard],
	// 		},
	// 		{ path: "**", redirectTo: "/1/portfolio" },
	// 	],
	// },

	// Portal
	{
		path: "",
		redirectTo: "/login",
		pathMatch: "full",
	},
	{
		path: "1",
		loadChildren: () =>
			import("./modules/platform/platform.module").then(
				(m) => m.PlatformModule
			),
		canActivate: [AuthPublicGuard],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' })],
	exports: [RouterModule],
	providers: [],
})
export class AppRoutingModule {}

export { userBasePath };
