/**
 * Fauna indexing file so we can use typed objects instead of strings for
 * indexing.
 */

//#region START COPY

type _keys =
	//
	| 'ORGANISATION'
	| 'TEAM'
	| 'RELATIONSHIP'
	| 'DOMAIN'
	//
	| 'USER'
	| 'ROLE'
	| 'MEMBERSHIP'
	| 'CONFIG'
	//
	| 'PROJECT'
	| 'JOB'
	| 'TASK'
	| 'PROCESS'
	//
	| 'MESSAGE'
	| 'NOTIFICATION'
	| 'PORTAL'
	| 'BLOB'
	| 'ACTION'
	| 'PADDOCK'
	| 'ANALYTICS'
	| 'REGION';

type _collections =
	//
	| 'organisations'
	| 'teams'
	| 'relationships'
	| 'domains'
	//
	| 'users'
	| 'roles'
	| 'memberships'
	| 'configs'
	//
	| 'projects'
	| 'jobs'
	| 'tasks'
	| 'processes'
	//
	| 'messages'
	| 'notifications'
	| 'portals'
	| 'blobs'
	| 'actions'
	| 'paddocks'
	| 'analytics'
	| 'regions';

const metaKeys: { [key in _keys]: _keys } = {
	//
	ORGANISATION: 'ORGANISATION',
	TEAM: 'TEAM',
	RELATIONSHIP: 'RELATIONSHIP',
	DOMAIN: 'DOMAIN',
	//
	USER: 'USER',
	ROLE: 'ROLE',
	MEMBERSHIP: 'MEMBERSHIP',
	CONFIG: 'CONFIG',
	//
	PROJECT: 'PROJECT',
	JOB: 'JOB',
	TASK: 'TASK',
	PROCESS: 'PROCESS',
	//
	MESSAGE: 'MESSAGE',
	NOTIFICATION: 'NOTIFICATION',
	PORTAL: 'PORTAL',
	BLOB: 'BLOB',
	ACTION: 'ACTION',
	PADDOCK: 'PADDOCK',

	ANALYTICS: 'ANALYTICS',
	REGION: 'REGION',
};

const collections: { [key in _keys]: _collections } = {
	//
	ORGANISATION: 'organisations',
	TEAM: 'teams',
	RELATIONSHIP: 'relationships',
	DOMAIN: 'domains',
	//
	USER: 'users',
	ROLE: 'roles',
	MEMBERSHIP: 'memberships',
	CONFIG: 'configs',
	//
	PROJECT: 'projects',
	JOB: 'jobs',
	TASK: 'tasks',
	PROCESS: 'processes',
	//
	MESSAGE: 'messages',
	NOTIFICATION: 'notifications',
	PORTAL: 'portals',
	BLOB: 'blobs',
	ACTION: 'actions',
	PADDOCK: 'paddocks',
	ANALYTICS: 'analytics',
	REGION: 'regions',
};

const c = collections;

function metaKeyFromCollection(c: _collections): _keys {
	return Object.keys(collections).find(
		(key) => collections[key] === c
	) as _keys;
}

const accessors = {
	ID: 'id',
	TEAM: 'team',
	EMAIL: 'email',
	USER: 'user',
	PROJECT: 'project',
	JOB: 'job',
	TASK: 'task',
	REGION: 'region',
};

const a = accessors;

// : { [key in _keys]: string }
const UUIDkeys = {
	ORGANISATION: 'og',
	TEAM: 'tm',
	RELATIONSHIP: 'rl',
	DOMAIN: 'dm',
	//
	USER: 'us',
	ROLE: 'rl',
	MEMBERSHIP: 'mb',
	CONFIG: 'cf',
	//
	PROJECT: 'pj',
	JOB: 'jb',
	TASK: 'tk',
	PROCESS: 'ps',
	//
	MESSAGE: 'me',
	NOTIFICATION: 'nf',
	PORTAL: 'pl',
	BLOB: 'bl',
	ACTION: 'ac',
	// Other
	FILE: 'fl',
	PRIVATEFILE: 'pf',
	AVATAR: 'av',
	TYPE: 'type',
	PADDOCK: 'pd',
	ANALYTICS: 'an',
	REGION: 're',
};

//#endregion

const g_by = (collection: string, accessor: string) => {
	return `${collection}_by_${accessor}`;
};

const g_all = (collection: string) => {
	return `all_${collection}`;
};

function getUUIDKeyName(uuidKey: string) {
	return Object.keys(UUIDkeys).find((key) => UUIDkeys[key] === uuidKey);
}

function by(key: string, accessor: string) {
	return indexes[`${key}_BY_${accessor}`];
}

function all(key: string) {
	return indexes[`${key}_ALL`];
}

// Eventually change this to an array an just have a check if its in it,
// if not then throw an error
const indexes = {
	//
	ORGANISATION_ALL: g_all(c.ORGANISATION),
	ORGANISATION_BY_ID: g_by(c.ORGANISATION, a.ID),
	//
	TEAM_ALL: g_all(c.TEAM),
	TEAM_BY_ID: g_by(c.TEAM, a.ID),
	//
	RELATIONSHIP_ALL: g_all(c.RELATIONSHIP),
	RELATIONSHIP_BY_ID: g_by(c.RELATIONSHIP, a.ID),
	//
	DOMAIN_ALL: g_all(c.DOMAIN),
	DOMAIN_BY_ID: g_by(c.DOMAIN, a.ID),
	// Users
	USER_ALL: g_all(c.USER),
	USER_BY_TEAM: g_by(c.USER, a.TEAM),
	USER_BY_EMAIL: g_by(c.USER, a.EMAIL),
	USER_BY_ID: g_by(c.USER, a.ID),
	//
	ROLE_ALL: g_all(c.ROLE),
	ROLE_BY_ID: g_by(c.ROLE, a.ID),
	ROLE_BY_USER: g_by(c.ROLE, a.USER),
	//
	CONFIG_ALL: g_all(c.CONFIG),
	CONFIG_BY_ID: g_by(c.CONFIG, a.ID),
	//
	MEMBERSHIP_ALL: g_all(c.MEMBERSHIP),
	MEMBERSHIP_BY_ID: g_by(c.MEMBERSHIP, a.ID),
	MEMBERSHIP_BY_TEAM: g_by(c.MEMBERSHIP, a.TEAM),
	//
	PROJECT_ALL: g_all(c.PROJECT),
	PROJECT_BY_ID: g_by(c.PROJECT, a.ID),
	PROJECT_BY_TEAM: g_by(c.PROJECT, a.TEAM),
	//
	JOB_ALL: g_all(c.JOB),
	JOB_BY_ID: g_by(c.JOB, a.ID),
	JOB_BY_TEAM: g_by(c.JOB, a.TEAM),
	JOB_BY_PROJECT: g_by(c.JOB, a.PROJECT),
	//
	TASK_ALL: g_all(c.TASK),
	TASK_BY_ID: g_by(c.TASK, a.ID),
	TASK_BY_PROJECT: g_by(c.TASK, a.PROJECT),
	TASK_BY_TEAM: g_by(c.TASK, a.TEAM),
	// TODO: task_by_whatever we are doing the messaging by

	PROCESS_ALL: g_all(c.PROCESS),
	PROCESS_BY_ID: g_by(c.PROCESS, a.ID),
	PROCESS_BY_PROJECT: g_by(c.PROCESS, a.PROJECT),
	PROCESS_BY_TEAM: g_by(c.PROCESS, a.TEAM),
	//
	MESSAGE_ALL: g_all(c.MESSAGE),
	MESSAGE_BY_ID: g_by(c.MESSAGE, a.ID),
	MESSAGE_BY_JOB: g_by(c.MESSAGE, a.JOB),
	MESSAGE_BY_TASK: g_by(c.MESSAGE, a.TASK),

	//
	NOTIFICATION_ALL: g_all(c.NOTIFICATION),
	NOTIFICATION_BY_ID: g_by(c.NOTIFICATION, a.ID),
	NOTIFICATION_BY_USER: g_by(c.NOTIFICATION, a.USER),
	NOTIFICATION_BY_JOB: g_by(c.NOTIFICATION, a.JOB),
	//
	// PORTALS_ALL: g_all(c.PORTAL),
	PORTAL_BY_ID: g_by(c.PORTAL, a.ID),
	PORTAL_BY_JOB: g_by(c.PORTAL, a.JOB),
	//
	BLOB_ALL: g_all(c.BLOB),
	BLOB_BY_ID: g_by(c.BLOB, a.ID),
	BLOB_BY_JOB: g_by(c.BLOB, a.JOB),
	BLOB_BY_TASK: g_by(c.BLOB, a.TASK),
	BLOB_BY_PROJECT: g_by(c.BLOB, a.PROJECT),
	//
	PADDOCK_ALL: g_all(c.PADDOCK),
	PADDOCK_BY_ID: g_by(c.PADDOCK, a.ID),
	PADDOCK_BY_USER: g_by(c.PADDOCK, a.USER),

	ANALYTICS_ALL: g_all(c.ANALYTICS),
	ANALYTICS_BY_ID: g_by(c.ANALYTICS, a.ID),
	ANALYTICS_BY_PROJECT: g_by(c.ANALYTICS, a.PROJECT),
	ANALYTICS_BY_REGION: g_by(c.ANALYTICS, a.REGION),

	REGION_ALL: g_all(c.REGION),
	REGION_BY_ID: g_by(c.REGION, a.ID),
	REGION_BY_PROJECT: g_by(c.REGION, a.PROJECT),

	ACTION_BY_ID: g_by(c.ACTION, a.ID),

	// SPECIAL
	PROCESS_BY_TYPE: 'processes_by_type',
};

export {
	_keys,
	_collections,
	metaKeyFromCollection,
	metaKeys,
	collections,
	UUIDkeys,
	accessors,
	indexes,
	by,
	all,
	getUUIDKeyName,
};
