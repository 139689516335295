import { Component, OnInit, Input } from "@angular/core";
import { AppService } from "@src/app/core/services/app.service";

@Component({
	selector: "app-tooltip",
	templateUrl: "./tooltip.component.html",
	styleUrls: ["./tooltip.component.scss"],
})
export class TooltipComponent implements OnInit {
	@Input() content;
	@Input() icon;
	@Input() class;
	@Input() color;

	constructor(public app: AppService) {}

	ngOnInit(): void {}
}
