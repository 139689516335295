import { Injectable } from "@angular/core";
import { AppService } from "@src/app/core/services/app.service";
import { EmpireService } from "@src/app/core/services/empire.service";
import { Subscription } from "rxjs";
import { BeeService } from "@src/app/core/services/bee/bee.service";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { IPaddock } from "@src/app/core/models/shared/models2";
import "leaflet-draw";
import * as L from "leaflet";

@Injectable({
	providedIn: "root",
})
export class CropMapService {
	constructor(
		public app: AppService,
		public bee: BeeService,
		public empire: EmpireService
	) {}
	public leaflet: L.Map;

	public toolPad:
		| "form"
		| "location"
		| "display"
		| "list"
		| "add"
		| "region"
		| "pin"
		| "line"
		| "circle"
		| "" = "";

	public form: "crop" | "pasture" | "" = "";
	public displayInfo: "crop" | "pasture" | "" = "";
	public dynamicTool: "form" | "save" | "" = "";
	public control: "map" | "tutorial" = "map";

	public pinCoord = null;

	drawnItems: L.FeatureGroup;
	sResize: Subscription;
	polygon: L.Draw.Polygon;
	crop2Layer: L.TileLayer;

	satellite: L.TileLayer;
	boundaries: L.GeoJSON[] = [];
	marker: L.Marker;
	markerGroup: L.LayerGroup;
	circleGroup: L.LayerGroup;
	currentMarker: L.Marker;

	boundariesToggled = false;
	sites = false;
	imageOpacity = false;
	ndviOpacity = false;
	cropOpacity = false;
	crop2Opacity = false;
	floodOpacity = false;
	harvestOpacity = false;
	highRes = false;

	paddockArea: number = null;
	paddockEditMode = false;
	paddockAddPin = false;
	distanceKm = null;
	distanceMeter = null;
	circleArea = null;

	visiblePaddocks = {};
	paddockLayers = {};
	selectedFeature = null;

	selectAllCrop = false;
	selectAllPasture = false;
	setView = true;

	center: any = [-26.498718, 134.374579];

	private ausBounds: L.LatLngBoundsExpression = [
		[-56.96119063892024, 100.587890625],
		[8.746969318459989, 163.16015625],
	];

	justFinishedDrawing = false;
	drawingLayer: L.Layer;

	toggleBoundaries() {
		this.boundariesToggled = !this.boundariesToggled;

		if (this.boundariesToggled) {
			this.boundaries.forEach((boundary) => {
				boundary.addTo(this.leaflet);
			});
		} else {
			this.boundaries.forEach((boundary) => {
				this.leaflet.removeLayer(boundary);
			});
		}
	}

	generateMap() {
		const minZoom = 4.5;

		this.leaflet = L.map("map", {
			center: this.center,
			zoom: minZoom,
			zoomSnap: 0.5,
			zoomControl: false,
			// maxBounds: this.ausBounds,
		});

		this.sResize = this.app.resize$.subscribe((x) => {
			setTimeout(() => {
				if (!this.leaflet) {
					return;
				}
				this.leaflet.invalidateSize();
			}, 300);
		});

		const map = L.tileLayer(
			"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
			{
				maxZoom: 20,
				minZoom,
				maxNativeZoom: 19,
				minNativeZoom: 3,
				attribution:
					'&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
			}
		);

		this.satellite = L.tileLayer(
			"https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWF0dGhld2FnIiwiYSI6ImNrcWMxbWYwbjA3aHgydmw0bHhxMGsxazcifQ.tv_EAMleOIf4H55P4MI9KA",
			{
				maxZoom: 20,
				minZoom,
				maxNativeZoom: 15,
				minNativeZoom: 3,
				opacity: 0,
				pane: "satellite",
				attribution:
					'&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
			}
		);

		this.crop2Layer = L.tileLayer(
			"https://agtuary-media-public.s3-ap-southeast-2.amazonaws.com/tiles/soil2/{z}/{x}/{y}.png",
			{
				maxZoom: 20,
				minZoom,
				maxNativeZoom: 12,
				minNativeZoom: 3,
				opacity: 0,
				pane: "soil2",
				attribution:
					'&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
			}
		);

		const bee = this.bee;

		//

		this.crop2Layer.getTileUrl = function (coords) {
			return bee.getUrl(coords, "soil2");
		};

		// @ts-ignore
		this.crop2Layer.createTile = function (coords, done) {
			const tile = document.createElement("img");

			L.DomEvent.on(
				tile,
				"load",
				L.Util.bind(this._tileOnLoad, this, done, tile)
			);
			L.DomEvent.on(
				tile,
				"error",
				L.Util.bind(this._tileOnError, this, done, tile)
			);

			if (this.options.crossOrigin || this.options.crossOrigin === "") {
				tile.crossOrigin =
					this.options.crossOrigin === true
						? ""
						: this.options.crossOrigin;
			}

			/*
             Alt tag is set to empty string to keep screen readers from reading URL and for compliance reasons
             http://www.w3.org/TR/WCAG20-TECHS/H67
            */
			tile.alt = "";

			/*
             Set role="presentation" to force screen readers to ignore this
             https://www.w3.org/TR/wai-aria/roles#textalternativecomputation
            */
			tile.setAttribute("role", "presentation");

			const url = this.getTileUrl(coords);

			bee.getBlob(tile, url);

			return tile;
		};

		// this.bee.getBlob();
		this.leaflet.createPane("rgb");
		this.leaflet.getPane("rgb").style.zIndex = "640";

		this.leaflet.createPane("soil");
		this.leaflet.getPane("soil").style.zIndex = "700";

		this.leaflet.createPane("soil2");
		this.leaflet.getPane("soil2").style.zIndex = "735";

		this.leaflet.createPane("harvest");
		this.leaflet.getPane("harvest").style.zIndex = "740";

		this.leaflet.createPane("ndvi");
		this.leaflet.getPane("ndvi").style.zIndex = "730";

		this.leaflet.createPane("satellite");
		this.leaflet.getPane("satellite").style.zIndex = "630";

		this.leaflet.createPane("geojsonPane");
		this.leaflet.getPane("geojsonPane").style.zIndex = "750";

		this.leaflet.createPane("pins");
		this.leaflet.getPane("pins").style.zIndex = "750";

		this.leaflet.createPane("site-labels");
		this.leaflet.getPane("site-labels").style.zIndex = "770";

		this.leaflet.createPane("flood");
		this.leaflet.getPane("flood").style.zIndex = "790";

		map.addTo(this.leaflet);
		this.satellite.addTo(this.leaflet);

		this.crop2Layer.addTo(this.leaflet);
	}

	searchAddress(value) {
		const provider = new OpenStreetMapProvider();
		provider.search({ query: value }).then((result) => {
			const coord = [];
			coord.push(result[0].y);
			coord.push(result[0].x);

			const icon = L.icon({
				iconUrl: this.empire.locationMapIcon,
				iconAnchor: [16, 32],
				iconSize: [40, 40],
			});
			// @ts-ignore
			this.marker = L.marker(coord, {
				icon,
				autoPan: true,
			}).addTo(this.leaflet);

			if (this.app.mapControl != "parcels") {
				this.leaflet.setView([coord[0], coord[1]], 12);
			}
		});
	}

	drawTool() {
		this.drawnItems = new L.FeatureGroup([], { pane: "paddock" });
		this.leaflet.createPane("paddock");
		this.drawnItems.addTo(this.leaflet);

		this.leaflet.on("mouseup touchend", (e) => {
			if (this.justFinishedDrawing) {
				// activates the edit once shape is drawn
				setTimeout(() => {
					// @ts-ignore
					this.drawingLayer.editing.enable();
				}, 10);

				this.justFinishedDrawing = false;
			}
		});

		this.leaflet.on("draw:created", (e) => {
			this.justFinishedDrawing = true;
			// @ts-ignore
			this.drawingLayer = e.layer as L.Layer;
			this.drawnItems.addLayer(this.drawingLayer);

			this.getAreaNewShape();

			if (!this.paddockAddPin) {
				this.app.popMessage = "form";
				this.dynamicTool = "form";
				this.paddockAddPin = false;
			} else {
				this.app.popMessage = "confirm";
			}
		});
	}

	// shape area at creation using draw tool
	getAreaNewShape() {
		// @ts-ignore
		const area = L.GeometryUtil.geodesicArea(
			// @ts-ignore
			this.drawingLayer.getLatLngs()[0]
		);
		const ha = area * 0.0001;
		this.paddockArea = Number(ha.toFixed(2));
	}

	// shape area for editing
	getAreaEditShape() {
		const area = L.GeometryUtil.geodesicArea(
			this.selectedFeature.getLatLngs()[0]
		);
		const ha = area * 0.0001;
		this.paddockArea = Number(ha.toFixed(2));
	}

	clearMap() {
		// clears the map from shapes and pins
		this.drawnItems.clearLayers();

		if (this.markerGroup) {
			this.markerGroup.clearLayers();
		}

		if (this.circleGroup) {
			this.circleGroup.clearLayers();
		}

		if (this.marker) {
			// @ts-ignore
			this.leaflet.removeLayer(this.marker);
			this.marker = undefined;
		}

		// disables click on map to avoid adding a pin
		this.leaflet.off("click");
	}

	visibilityControl(pad: IPaddock) {
		this.bee.ctx.paddock = pad;

		if (this.visiblePaddocks[pad.id]) {
			this.visiblePaddocks[pad.id] = false;
			this.paddockLayers[pad.id].remove();
		} else {
			this.visiblePaddocks[pad.id] = true;
			this.displayPaddock(pad);
		}
	}

	displayPaddock(pad: IPaddock) {
		const feature = JSON.parse(pad.geojson).features;

		if (feature.length > 0) {
			const geojsonFeature = JSON.parse(pad.geojson);

			const coordinates =
				geojsonFeature.features[0].geometry.coordinates[0];
			const reverse = coordinates.map((arry) => {
				return arry.reverse();
			});

			this.paddockLayers[pad.id] = L.polygon(reverse, {
				color: "#F28063",
				pane: "geojsonPane",
			});
			this.paddockLayers[pad.id].addTo(this.leaflet);

			let content = null;
			if (pad.cropType === "crop") {
				content = `<p>Crop: <strong>${
					pad.values.crop
				}</strong><br />Growth: <strong>${
					pad.values.growth ? pad.values.growth : "-"
				}</strong><br />Area: <strong>${
					pad.values.area ? pad.values.area : "-"
				} ha</strong></p><br /><button class="edit-popup">Edit shape</button>`;
			} else {
				content = `<p>Pasture: <strong>${
					pad.values.pasture
				}</strong><br />Livestock: <strong>${
					pad.values.livestock
				}</strong><br />Count: <strong>${
					pad.values.livestockCount ? pad.values.livestockCount : "-"
				}</strong><br />Capacity: <strong>${
					pad.values.maxCapacity ? pad.values.maxCapacity : "-"
				}</strong><br />Area: <strong>${
					pad.values.area ? pad.values.area : "-"
				} ha</strong></p><br /><button class="edit-popup">Edit shape</button>`;
			}

			const popup = new L.Popup({
				autoPan: true,
				keepInView: true,
			}).setContent(content);

			const bounds = this.paddockLayers[pad.id].getBounds();
			const center = bounds.getCenter();
			popup.setLatLng(center);

			this.paddockLayers[pad.id].bindPopup(popup).on("popupopen", () => {
				const editBtn = document.querySelector(".edit-popup");
				editBtn.addEventListener("click", () => {
					this.setView = false;
					this.editPaddockShape(pad.id);
				});
			});

			if (this.setView) {
				this.leaflet.setView([center.lat, center.lng], 12);
			}
		} else {
			this.dropPin(pad);
		}
	}

	dropPin(pad: IPaddock) {
		const icon = L.icon({
			iconUrl: this.empire.dropPin,
			iconAnchor: [16, 32],
			iconSize: [30, 30],
		});

		this.paddockLayers[pad.id] = L.marker(pad.values.pin, {
			icon,
			pane: "geojsonPane",
		});

		this.paddockLayers[pad.id].addTo(this.leaflet);

		if (this.setView) {
			this.leaflet.setView(pad.values.pin, 12);
		}
	}

	editPaddockShape(id: string) {
		this.leaflet.closePopup();

		let pad = null;
		Object.values(this.bee.state.paddocks).forEach((paddock) => {
			if (id === paddock.id) {
				pad = paddock;
			}
		});

		if (this.visiblePaddocks[pad.id]) {
			this.visibilityControl(pad);
		}

		this.toolPad = "";
		this.displayInfo = "";
		this.drawnItems.clearLayers();

		if (JSON.parse(pad.geojson).features.length > 0) {
			this.app.popMessage = "update";
			this.dynamicTool = "save";
			this.paddockEditMode = true;

			this.bee.ctx.paddock = pad;

			const geojsonFeature = JSON.parse(pad.geojson);
			this.selectedFeature = this.paddockLayers[pad.id];

			const coordinates =
				geojsonFeature.features[0].geometry.coordinates[0];
			const reverse = coordinates.map((arry) => {
				return arry.reverse();
			});
			this.selectedFeature = L.polygon(reverse, {
				color: "#F28063",
				pane: "geojsonPane",
			});

			this.getAreaEditShape();

			this.selectedFeature.editing.enable();
			this.drawnItems.addLayer(this.selectedFeature);

			const center = this.selectedFeature.getCenter();
			if (this.setView) {
				this.leaflet.setView([center.lat, center.lng], 12);
			}
		} else {
			this.dropPin(pad);
			this.bee.ctx.paddock = pad;

			this.paddockAddPin = true;
			this.app.popMessage = "pin";
			this.dynamicTool = "";
		}
	}
	calculateDistance(latlng) {
		const lat1 = latlng[0].lat;
		const lng1 = latlng[0].lng;
		const lat2 = latlng[1].lat;
		const lng2 = latlng[1].lng;

		const R = 6371;
		const dLat = this.degToRad(lat2 - lat1);
		const dLon = this.degToRad(lng2 - lng1);

		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(this.degToRad(lat1)) *
				Math.cos(this.degToRad(lat2)) *
				Math.sin(dLon / 2) *
				Math.sin(dLon / 2);

		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

		this.distanceKm = (R * c).toFixed(2);
		this.distanceMeter = this.distanceKm * 1000;
		this.app.popMessage = "distance";
	}

	calculateCircleArea(r) {
		const p = 3.14159;
		this.circleArea = (p * Math.pow(r, 2)).toFixed(2);
	}

	degToRad(deg) {
		return deg * (Math.PI / 180);
	}

	mapDestroy() {
		this.leaflet.remove();
		this.sResize.unsubscribe();
	}

	findLocation() {
		this.toolPad = "location";
		this.paddockEditMode = false;

		this.leaflet.locate({
			setView: true,
			maxZoom: 15,
			timeout: 10000,
			maximumAge: 100000,
		});

		const icon = L.icon({
			iconUrl: this.empire.locationMapIcon,
			iconAnchor: [16, 32],
			iconSize: [40, 40],
		});

		navigator.geolocation.getCurrentPosition(
			(position) => {
				if (this.currentMarker) {
					this.leaflet.removeLayer(this.currentMarker);
					this.currentMarker = undefined;
				}

				const {
					coords: { latitude, longitude },
				} = position;
				this.currentMarker = L.marker([latitude, longitude], {
					icon,
					draggable: false,
					autoPan: true,
				});
				this.currentMarker.addTo(this.leaflet);
			},
			(error) => {},
			{ enableHighAccuracy: true, maximumAge: 0, timeout: 10000 }
		);

		this.crop2Layer.addTo(this.leaflet);

		setTimeout(() => {
			this.toolPad = "";
		}, 3500);
	}

	drawCircle(mapID: string) {
		this.toolPad = "circle";

		const latlng = [];
		this.circleGroup = L.layerGroup().addTo(this.leaflet);

		this.leaflet.on("click", (e) => {
			// @ts-ignore
			if (latlng.length >= 2) {
				this.leaflet.off("click");
				return;
			}

			const icon = L.icon({
				iconUrl: this.empire.dropPin,
				iconAnchor: [16, 32],
				iconSize: [30, 30],
			});
			// @ts-ignore
			const marker = L.marker(e.latlng, {
				icon,
				draggable: false,
				autoPan: true,
			}).addTo(this.circleGroup);

			latlng.push(marker.getLatLng());

			if (latlng.length === 2) {
				this.calculateDistance(latlng);
				// @ts-ignore
				L.circle([latlng[0].lat, latlng[0].lng], {
					radius: this.distanceMeter,
				}).addTo(this.circleGroup);

				this.calculateCircleArea(this.distanceKm);
				// this.drawPolyline(latlng);
			}
		});
	}
}
