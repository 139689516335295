<div class="popup">
	<div class="background"></div>
	<div
		class="window"
		[style.width.px]="popup.width"
		[style.height.px]="popup.height"
	>
		<ng-container #container> </ng-container>
	</div>
</div>
