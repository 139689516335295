import { Component, OnInit } from '@angular/core';
import { BeeService } from '@src/app/core/services/bee/bee.service';
import { environment } from '@src/environments/environment';
import { AppService } from '@src/app/core/services/app.service';

@Component({
	selector: 'app-container',
	templateUrl: './container.component.html',
	styleUrls: ['./container.component.scss'],
})
export class ContainerComponent implements OnInit {
	smallDevice = false;

	public environment = environment;
	constructor(public bee: BeeService, public app: AppService) {}

	onResize(event) {
		this.app.determineDevice();
		this.app.resize$.next();
	}

	ngOnInit() {
		// this is for testing and needs to be removed
		// set happens on user signup
		// localStorage.setItem("onboardingActive", "true");

		if (localStorage.getItem('onboardingActive') == 'true') {
			this.app.messageWindow = 'welcome';
		}
	}
}
