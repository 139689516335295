import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";
import { BeeService } from "@src/app/core/services/bee/bee.service";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { AppService } from "@src/app/core/services/app.service";
import { EmpireService } from "@src/app/core/services/empire.service";
import { IRegion } from "@src/app/core/models/shared/models2";
import { CropMapService } from "@src/app/core/services/crop-map/crop-map.service";

@Component({
	selector: "app-paddock-form",
	templateUrl: "./paddock-form.component.html",
	styleUrls: ["./paddock-form.component.scss"],
})
export class PaddockFormComponent implements OnInit {
	cropData: FormGroup;
	pastureData: FormGroup;

	selectedCrop = "";
	selectedLivestock = "";
	maxCap = "";
	maxDse: "yes" | "no" = "yes";
	badSignalArea: "yes" | "no" = "no";
	note: "crop" | "pasture" | "" = "";

	newPropertyName: string = "";
	newClientName: string = "";

	@Input() pasturePaddock;
	@Input() cropPaddock;

	message = "";

	//#region inputs
	crops = [
		"N/A",
		"Wheat",
		"Barley",
		"Canola",
		"Cereals",
		"Pulses",
		"Chickpeas",
		"Faba beans",
		"Field peas",
		"Lentils",
		"Lupins",
		"Oats",
		"Triticale",
		"Sorghum",
		"Cottonseed",
		"Cotton lint",
		"Rice",
		"Corn (maize)",
		"Soybeans",
		"Sunflower",
		"Other",
	];

	stages = [
		"N/A",
		"Pre-planting",
		"Sowing",
		"Seedling",
		"Jointing",
		"Flowering",
		"Filling",
		"Maturity",
		"Harvested",
	];

	seasons = ["N/A", "Summer", "Winter"];

	years = [
		"N/A",
		"2022",
		"2021",
		"2020",
		"2019",
		"2018",
		"2017",
		"2016",
		"2015",
		"2014",
		"2013",
		"2012",
		"2011",
		"2010",
		"2009",
		"2008",
		"2007",
		"2006",
		"2005",
		"2004",
		"2003",
		"2002",
		"2001",
		"2000",
	];

	pastures = [
		"N/A",
		"Lucerna",
		"Native",
		"Improved",
		"Improved rundown",
		"Sub tropicals",
	];

	livestocks = ["N/A", "Beef cattle", "Sheep", "Dairy", "Other"];

	reportProducts = {};
	product: "productivity" | "rainfall" | "soil" | "temperature" | "" = "";

	//#endregion

	constructor(
		public map: CropMapService,
		public bee: BeeService,
		public app: AppService,
		private empire: EmpireService
	) {}

	ngOnInit(): void {
		for (let p of this.bee.productCatalogue) {
			this.reportProducts[p.product.metadata.product] = false;
		}

		// creating a new paddock
		if (!this.map.paddockEditMode) {
			this.cropData = new FormGroup({
				crop: new FormControl("", [Validators.required]),
				otherCrop: new FormControl(""),
				growth: new FormControl(""),
				seasonCrop: new FormControl("Winter"),
				yearCrop: new FormControl("2021"),
				nameCrop: new FormControl(""),
				cropNote: new FormControl(""),
			});

			this.pastureData = new FormGroup({
				pasture: new FormControl("", [Validators.required]),
				livestock: new FormControl("", [Validators.required]),
				otherLivestock: new FormControl(""),
				livestockCount: new FormControl(""),
				maxCapacity: new FormControl(""),
				seasonPasture: new FormControl("Winter"),
				yearPasture: new FormControl("2021"),
				namePasture: new FormControl(""),
				pastureNote: new FormControl(""),
			});
		} else {
			// editing crop paddock
			if (this.cropPaddock.length > 0) {
				this.cropData = new FormGroup({
					crop: new FormControl(this.cropPaddock[0].values.crop, [
						Validators.required,
					]),
					otherCrop: new FormControl(""),
					growth: new FormControl(this.cropPaddock[0].values.growth),
					seasonCrop: new FormControl(
						this.cropPaddock[0].values.season
					),
					yearCrop: new FormControl(this.cropPaddock[0].values.year),
					nameCrop: new FormControl(this.cropPaddock[0].values.name),
					cropNote: new FormControl(this.cropPaddock[0].values.note),
				});
			}

			// editing pasture paddock
			if (this.pasturePaddock.length > 0) {
				this.pastureData = new FormGroup({
					pasture: new FormControl(
						this.pasturePaddock[0].values.pasture,
						[Validators.required]
					),
					livestock: new FormControl(
						this.pasturePaddock[0].values.livestock,
						[Validators.required]
					),
					otherLivestock: new FormControl(""),
					livestockCount: new FormControl(
						this.pasturePaddock[0].values.livestockCount
					),
					maxCapacity: new FormControl(
						this.pasturePaddock[0].values.maxCapacity
					),
					seasonPasture: new FormControl(
						this.pasturePaddock[0].values.season
					),
					yearPasture: new FormControl(
						this.pasturePaddock[0].values.year
					),
					namePasture: new FormControl(
						this.pasturePaddock[0].values.name
					),
					pastureNote: new FormControl(
						this.pasturePaddock[0].values.note
					),
				});
			}
		}
	}

	//#region form control
	get crop() {
		return this.cropData.get("crop");
	}
	get otherCrop() {
		return this.cropData.get("otherCrop");
	}
	get cropNote() {
		return this.cropData.get("cropNote");
	}
	get growth() {
		return this.cropData.get("growth");
	}
	get seasonCrop() {
		return this.cropData.get("seasonCrop");
	}
	get yearCrop() {
		return this.cropData.get("yearCrop");
	}
	get nameCrop() {
		return this.cropData.get("nameCrop");
	}
	get pasture() {
		return this.pastureData.get("pasture");
	}
	get maxCapacity() {
		return this.pastureData.get("maxCapacity");
	}
	get livestock() {
		return this.pastureData.get("livestock");
	}
	get otherLivestock() {
		return this.cropData.get("otherLivestock");
	}
	get livestockCount() {
		return this.pastureData.get("livestockCount");
	}
	get seasonPasture() {
		return this.pastureData.get("seasonPasture");
	}
	get yearPasture() {
		return this.pastureData.get("yearPasture");
	}
	get namePasture() {
		return this.pastureData.get("namePasture");
	}
	get pastureNote() {
		return this.pastureData.get("pastureNote");
	}
	//#endregion

	get parcelDetails() {
		return {
			total: this.app.paymentPreview.area,
			area: Object.values(this.app.paymentPreview.areas),
			count: Object.keys(this.app.paymentPreview.areas).length,
		};
	}

	clearParcels() {
		this.app.paymentPreview = undefined;
		for (var key in this.app.selectedParcelsDict) {
			delete this.app.selectedParcelsDict[key];
		}
		// this.mapService.generateParcels();
	}

	checkbox(cap) {
		if (cap === "Yes") {
			this.maxDse = "yes";
			this.maxCap = cap;
		} else {
			this.maxDse = "no";
			this.maxCap = cap;
		}
	}

	badArea(cap) {
		if (cap === "Yes") {
			this.note = "crop";
			this.badSignalArea = "yes";
		} else {
			this.note = "";
			this.badSignalArea = "no";
		}
	}

	selectCrop() {
		this.selectedCrop = this.cropData.value.crop;
	}

	selectLivestock() {
		this.selectedLivestock = this.pastureData.value.livestock;
	}

	// this function controls both create and update
	savePaddockData(type) {
		this.app.slider = "";
		this.note = "";
		this.map.dynamicTool = "";

		// if (
		// 	this.map.drawnItems.toGeoJSON()["features"].length == 0 &&
		// 	!this.map.marker
		// ) {
		// 	this.message = "Please draw a paddock or drop a pin on the map.";

		// 	setTimeout(() => {
		// 		this.message = "";
		// 	}, 3000);

		// 	return;
		// }

		if (type === "crop") {
			if (!this.cropData.valid) {
				this.message = "Please fill in required fields.";

				setTimeout(() => {
					this.message = "";
				}, 3000);

				return;
			}

			this.map.toolPad = "";
			this.map.form = "";

			let crop = this.cropData.value;

			if (crop.crop === "Other") {
				crop.crop = crop.otherCrop;
			}

			let data = {
				crop: crop.crop,
				growth: crop.growth,
				note: crop.cropNote,
				pin: this.map.pinCoord,
				area: this.map.paddockArea,
				season: crop.seasonCrop,
				year: crop.yearCrop,
				name: crop.nameCrop,
			};

			let paddock = {
				created: new Date().toISOString(),
				cropType: "crop",
				geojson: JSON.stringify(this.map.drawnItems.toGeoJSON()),
				team: this.bee.ctx.team.id,
				user: this.bee.ctx.user.id,
				values: data,
			};

			if (!this.map.paddockEditMode) {
				// creating paddock
				this.bee.hive.paddocks.create(paddock).then((pad) => {
					this.map.setView = false;
					this.map.visibilityControl(pad[0]);
					this.map.clearMap();
				});

				this.app.popMessage = "save";
				setTimeout(() => {
					this.app.popMessage = "";
				}, 3000);
			} else {
				// editing paddock
				let dataEdit = {
					...data,
					pin: this.cropPaddock[0].values.pin,
					area: this.cropPaddock[0].values.area,
				};
				this.bee.hive.paddocks.update(
					{
						...paddock,
						geojson: this.cropPaddock[0].geojson,
						team: this.bee.ctx.team.id,
						user: this.bee.ctx.user.id,
						values: dataEdit,
					},
					this.cropPaddock[0].id
				);
				this.map.paddockEditMode = false;
				this.app.popMessage = "save";
				setTimeout(() => {
					this.app.popMessage = "";
				}, 3000);
			}

			this.cropData.reset();
		} else {
			if (!this.pastureData.valid) {
				this.message = "Please fill in required fields.";

				setTimeout(() => {
					this.message = "";
				}, 3000);

				return;
			}

			this.map.toolPad = "";
			this.map.form = "";

			let pasture = this.pastureData.value;

			if (pasture.livestock === "Other") {
				pasture.livestock = pasture.otherLivestock;
			}

			if (pasture.maxCapacity === "") {
				pasture.maxCapacity = pasture.livestockCount;
			} else {
				pasture.maxCapacity;
			}

			let data = {
				livestock: pasture.livestock,
				livestockCount: pasture.livestockCount,
				pasture: pasture.pasture,
				maxCapacity: pasture.maxCapacity,
				note: pasture.pastureNote,
				pin: this.map.pinCoord,
				area: this.map.paddockArea,
				season: pasture.seasonPasture,
				year: pasture.yearPasture,
				name: pasture.namePasture,
			};

			let paddock = {
				created: new Date().toISOString(),
				cropType: "pasture",
				geojson: JSON.stringify(this.map.drawnItems.toGeoJSON()),
				team: this.bee.ctx.team.id,
				user: this.bee.ctx.user.id,
				values: data,
			};

			if (!this.map.paddockEditMode) {
				// creating paddock
				this.bee.hive.paddocks.create(paddock).then((pad) => {
					this.map.setView = false;
					this.map.visibilityControl(pad[0]);
					this.map.clearMap();
				});

				this.app.popMessage = "save";
				setTimeout(() => {
					this.app.popMessage = "";
				}, 3000);
			} else {
				// editing paddock
				let dataEdit = {
					...data,
					pin: this.pasturePaddock[0].values.pin,
					area: this.pasturePaddock[0].values.area,
				};
				this.bee.hive.paddocks.update(
					{
						...paddock,
						geojson: this.pasturePaddock[0].geojson,
						team: this.bee.ctx.team.id,
						user: this.bee.ctx.user.id,
						values: dataEdit,
					},
					this.pasturePaddock[0].id
				);
				this.map.paddockEditMode = false;
				this.app.popMessage = "save";
				setTimeout(() => {
					this.app.popMessage = "";
				}, 3000);
			}

			this.pastureData.reset();
		}
	}

	checkingOut = false;

	saveClientdata() {}

	openCheckout() {
		window.open(this.bee.checkoutUrl, "_blank");
	}

	checkReport() {}
}
