<div
	class="app-container"
	id="agtuary-app-container"
	fxFlexFill
	fxLayout="row"
	fxLayoutAlign="space-between none"
	(window:resize)="onResize($event)"
>
	<app-sidebar-navigation
		class="side-navigation sidebar"
		[class.collapse]="this.app.collapseSidebar"
		*ngIf="
			this.app.deviceSize == 'tablet' || this.app.deviceSize == 'desktop'
		"
	>
	</app-sidebar-navigation>

	<div
		class="outlet-container"
		*ngIf="
			this.app.deviceSize == 'tablet' || this.app.deviceSize == 'desktop'
		"
	>
		<div
			class="background"
			[style.background-image]="app.backgroundCss"
			[style.opacity]="app.backgroundOpacity"
		></div>

		<div class="outlet">
			<div *ngIf="false; else loaded" class="spinner">
				<img src="assets/loading.png" />
			</div>
			<ng-template #loaded>
				<router-outlet></router-outlet>
			</ng-template>
		</div>
	</div>

	<!-- Graincopr mobile outlet -->
	<div
		class="map"
		fxLayout="column"
		fxLayoutAlign="start center"
		*ngIf="this.app.deviceSize == 'mobile' && this.app.mainClient"
	>
		<app-header class="editor-header"></app-header>
		<app-crop-map></app-crop-map>
	</div>

	<!-- Regular users on mobile -->
	<div
		class="welcome"
		*ngIf="this.app.deviceSize == 'mobile' && !this.app.mainClient"
	>
		<app-header class="editor-header"></app-header>
		<div class="row" fxLayout="row" fxLayoutAlign="space-between none">
			<i class="material-icons green">verified</i>
			<p>Thank you for signing up to the Agtuary platform.</p>
		</div>
		<div class="row" fxLayout="row" fxLayoutAlign="space-between none">
			<i class="material-icons green">verified</i>
			<p>
				The application is optimised for a desktop. Please use a
				computer to explore variety of tools and features.
			</p>
		</div>
	</div>
</div>
