import {
	ApplicationRef,
	Component,
	ComponentFactoryResolver,
	ComponentRef,
	EmbeddedViewRef,
	Injectable,
	Injector,
	Type,
} from "@angular/core";
import { PopupParentComponent } from "@components/popup-parent/popup-parent.component";
import { Subject } from "rxjs";
import { BasePopup } from "@core/models/popup";

const popupSizes = {
	thin: { width: 400, height: 200 },
	normal: { width: 400, height: 300 },
	wide: { width: 700, height: 500 },
};

@Injectable({
	providedIn: "root",
})
export class PopupService {
	popupRef: ComponentRef<PopupParentComponent>;

	public defaultWidth: number = 500;
	public defaultHeight: number = 500;

	public width: number = 500;
	public height: number = 500;

	onAdd = new Subject<any>();
	onClose = new Subject<any>();

	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private appRef: ApplicationRef,
		private injector: Injector
	) {}

	public show<K, T extends BasePopup<K>>(
		child: Type<T>,
		add?: (data: K) => void,
		close?: () => void,
		size: "thin" | "normal" | "wide" = "normal"
	): T {
		const contentFactory =
			this.componentFactoryResolver.resolveComponentFactory(child);

		const templateFactory =
			this.componentFactoryResolver.resolveComponentFactory(
				PopupParentComponent
			);

		const componentRef = templateFactory.create(this.injector);
		// componentRef.instance.width = this.width;
		// componentRef.instance.height = this.height;

		this.width = popupSizes[size].width;
		this.height = popupSizes[size].height;

		let c = componentRef.instance.container.createComponent(contentFactory);

		// const popupFactory = this.componentFactoryResolver.resolveComponentFactory(
		// 	PopupParentComponent
		// );

		// const popupRef = popupFactory.create(this.injector);

		this.appRef.attachView(componentRef.hostView);

		const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
			.rootNodes[0] as HTMLElement;
		document.body.appendChild(domElem);

		this.popupRef = componentRef;

		// popupRef.instance.adHost.viewContainerRef.createComponent(popupFactory);

		// popupRef.instance.loadComponent(child, {});

		c.instance.onAdd = add;
		c.instance.onClose = close;

		return c.instance;
	}

	public close() {
		this.appRef.detachView(this.popupRef.hostView);
		this.popupRef.destroy();

		this.onClose.next();

		this.width = this.defaultWidth;
		this.height = this.defaultHeight;
	}
}
