<div class="container">
	<div
		class="header"
		fxLayout="row"
		fxLayoutAlign="space-between center"
		*ngIf="
			this.app.deviceSize == 'tablet' || this.app.deviceSize == 'desktop'
		"
	>
		<div fxLayout="row" fxLayoutAlign="space-between center">
			<h4 *ngIf="this.map.control === 'tutorial'">Tutorials</h4>

			<app-waiting-icon
				[visible]="this.app.submitting.settings"
			></app-waiting-icon>
		</div>

		<div
			*ngIf="this.map.control === 'map'"
			fxLayout="row"
			fxLayoutAlign="space-between center"
		>
			<input
				type="text"
				placeholder="Address, postcode"
				[(ngModel)]="mapSearch"
			/>
			<app-tooltip
				(click)="searchAddress()"
				content="Search"
				icon="search"
				class="right"
				color="green icon"
			></app-tooltip>
		</div>

		<div
			class="btn-container"
			fxLayout="row"
			fxLayoutAlign="space-between center"
		>
			<app-tooltip
				*ngIf="this.map.control === 'tutorial'"
				(click)="recreateMap()"
				icon="arrow_back_ios_new"
				class="left"
				color="green icon"
				content="Back"
			></app-tooltip>
			<button
				class="solid-button"
				(click)="this.map.control = 'tutorial'"
				*ngIf="this.map.control === 'map'"
			>
				Tutorials
			</button>
		</div>
	</div>

	<div class="map-container" *ngIf="this.map.control === 'map'">
		<app-popup-message
			class="notifications"
			*ngIf="this.app.popMessage !== ''"
			(displayForm)="displayForm()"
			(updateShape)="updateShape()"
		></app-popup-message>

		<div class="map-frame">
			<div id="map"></div>

			<div>
				<div
					class="main-menu"
					*ngIf="
						this.app.deviceSize == 'tablet' ||
						(this.app.deviceSize == 'desktop' &&
							this.map.control === 'map')
					"
				>
					<div
						class="navIcon"
						fxLayoutAlign=" center"
						(click)="displayPaddock()"
						[class.active-nav]="this.map.toolPad === 'list'"
					>
						<app-tooltip
							content="Paddocks"
							icon="reorder"
							class="left"
							color="this.map.toolPad === 'list' ? 'green' : 'black'"
						></app-tooltip>
					</div>
					<div
						class="navIcon"
						fxLayoutAlign=" center"
						(click)="displayRegion()"
						[class.active-nav]="this.map.toolPad === 'region'"
					>
						<app-tooltip
							content="Regions"
							icon="filter_list"
							class="left"
							color="this.map.toolPad === 'region' ? 'green' : 'black'"
						></app-tooltip>
					</div>
					<div
						class="navIcon"
						fxLayoutAlign=" center"
						(click)="displaySetting()"
						[class.active-nav]="this.map.toolPad === 'display'"
					>
						<app-tooltip
							content="Settings"
							icon="opacity"
							class="left"
							color="this.map.toolPad === 'display' ? 'green' : 'black'"
						></app-tooltip>
					</div>
				</div>
			</div>

			<div>
				<div class="tools-menu">
					<div
						class="navIcon"
						fxLayoutAlign=" center"
						(click)="addPaddock()"
						[class.active-nav]="this.map.toolPad === 'add'"
					>
						<app-tooltip
							content="Draw"
							icon="control_point"
							class="left"
							color="this.map.toolPad === 'add' ? 'green' : 'black'"
						></app-tooltip>
					</div>
					<div
						class="navIcon"
						(click)="addMarker()"
						fxLayoutAlign=" center"
						[class.active-nav]="this.map.toolPad === 'pin'"
					>
						<app-tooltip
							content="Pin"
							icon="push_pin"
							class="left"
							color="this.map.toolPad === 'pin' ? 'green' : 'black'"
						></app-tooltip>
					</div>
					<div
						class="navIcon"
						(click)="drawLine()"
						fxLayoutAlign=" center"
						[class.active-nav]="this.map.toolPad === 'line'"
					>
						<app-tooltip
							content="Distance"
							icon="architecture"
							class="left"
							color="this.map.toolPad === 'line' ? 'green' : 'black'"
						></app-tooltip>
					</div>
					<div
						class="navIcon"
						(click)="drawCircle()"
						fxLayoutAlign=" center"
						[class.active-nav]="this.map.toolPad === 'circle'"
					>
						<app-tooltip
							content="Radius"
							icon="radio_button_unchecked"
							class="left"
							color="this.map.toolPad === 'circle' ? 'green' : 'black'"
						></app-tooltip>
					</div>
					<div
						class="navIcon"
						fxLayoutAlign=" center"
						(click)="findLocation()"
						[class.active-nav]="this.map.toolPad === 'location'"
					>
						<app-tooltip
							content="Location"
							icon="my_location"
							class="left"
							color="this.map.toolPad === 'location' ? 'green' : 'black'"
						></app-tooltip>
					</div>
				</div>
			</div>

			<div *ngIf="this.app.mapControl == 'parcels'">
				<div class="tools-menu">
					<div
						class="navIcon"
						fxLayoutAlign=" center"
						(click)="addPaddock()"
						[class.active-nav]="this.map.toolPad === 'add'"
					>
						<app-tooltip
							content="Draw"
							icon="control_point"
							class="left"
							color="this.map.toolPad === 'add' ? 'green' : 'black'"
						></app-tooltip>
					</div>
					<div
						class="navIcon"
						fxLayoutAlign=" center"
						(click)="findLocation()"
						[class.active-nav]="this.map.toolPad === 'location'"
					>
						<app-tooltip
							content="Location"
							icon="my_location"
							class="left"
							color="this.map.toolPad === 'location' ? 'green' : 'black'"
						></app-tooltip>
					</div>
				</div>
			</div>

			<div class="tools-zoom">
				<div
					class="navIcon"
					fxLayoutAlign=" center"
					(click)="zoomIn()"
					[class.active-nav]="this.map.toolPad === 'location'"
				>
					<app-tooltip
						content="Zoom in"
						icon="add"
						class="right"
						color="this.map.toolPad === 'location' ? 'green' : 'black'"
					></app-tooltip>
				</div>
				<div
					class="navIcon"
					fxLayoutAlign=" center"
					(click)="zoomOut()"
					[class.active-nav]="this.map.toolPad === 'location'"
				>
					<app-tooltip
						content="Zoom out"
						icon="remove"
						class="right"
						color="this.map.toolPad === 'location' ? 'green' : 'black'"
					></app-tooltip>
				</div>
			</div>

			<!-- menue for mobile -->

			<div
				class="menue-bar"
				fxLayout="row"
				fxLayoutAlign="space-around center"
				*ngIf="this.app.deviceSize == 'mobile'"
			>
				<div
					class="navIcon"
					fxLayout="column"
					fxLayoutAlign="space-between center"
					(click)="addPaddock()"
					[class.active-nav]="this.map.toolPad === 'add'"
				>
					<i class="material-icons">control_point</i>
					<p>Paddock</p>
				</div>
				<div
					class="navIcon"
					fxLayout="column"
					fxLayoutAlign="space-between center"
					(click)="displayPaddock()"
					[class.active-nav]="this.map.toolPad === 'list'"
				>
					<i class="material-icons">reorder</i>
					<p>List</p>
				</div>
				<div
					class="navIcon"
					fxLayout="column"
					fxLayoutAlign="space-between center"
					(click)="displayRegion()"
					[class.active-nav]="this.map.toolPad === 'region'"
				>
					<i class="material-icons">filter_list</i>
					<p>Regions</p>
				</div>
				<div
					class="navIcon"
					fxLayout="column"
					fxLayoutAlign="space-between center"
					(click)="displaySetting()"
					[class.active-nav]="this.map.toolPad === 'display'"
				>
					<i class="material-icons">opacity</i>
					<p>Display</p>
				</div>
			</div>
		</div>

		<div class="slide-container" *ngIf="this.map.toolPad === 'list'">
			<div
				class="top-bar"
				fxLayout="row"
				fxLayoutAlign="space-between center"
			>
				<h4>Paddocks list</h4>
				<i class="material-icons cross" (click)="close()">close</i>
			</div>
			<app-paddock></app-paddock>
		</div>

		<div
			class="slide-container range"
			*ngIf="this.map.toolPad === 'display'"
		>
			<div
				class="top-bar"
				fxLayout="row"
				fxLayoutAlign="space-between center"
			>
				<h4>Display settings</h4>
				<i class="material-icons cross" (click)="close()">close</i>
			</div>

			<div
				class="divs"
				fxLayout="row"
				fxLayoutAlign="space-between center"
			>
				<p>Boundaries</p>
				<i
					class="material-icons green toggle-btn"
					(click)="toggleBoundaries()"
					*ngIf="this.map.boundariesToggled"
					>toggle_on</i
				>
				<i
					class="material-icons red toggle-btn"
					(click)="toggleBoundaries()"
					*ngIf="!this.map.boundariesToggled"
					>toggle_off</i
				>
			</div>

			<div
				class="divs"
				fxLayout="row"
				fxLayoutAlign="space-between center"
			>
				<p>Sites</p>
				<i
					class="material-icons green toggle-btn"
					(click)="hideSites()"
					*ngIf="this.map.sites"
					>toggle_on</i
				>
				<i
					class="material-icons red toggle-btn"
					(click)="showSites()"
					*ngIf="!this.map.sites"
					>toggle_off</i
				>
			</div>

			<div
				class="divs"
				fxLayout="row"
				fxLayoutAlign="space-between center"
			>
				<p>High res satellite imagery</p>
				<i
					class="material-icons green toggle-btn"
					(click)="changeHighResOpacity('hide')"
					*ngIf="this.map.highRes"
					>toggle_on</i
				>
				<i
					class="material-icons red toggle-btn"
					(click)="changeHighResOpacity('show')"
					*ngIf="!this.map.highRes"
					>toggle_off</i
				>
			</div>

			<div class="divs">
				<div fxLayout="row" fxLayoutAlign="space-between center">
					<p>Crop mask</p>
					<i
						class="material-icons green toggle-btn"
						(click)="changeCrop2Opacity('hide')"
						*ngIf="this.map.crop2Opacity"
						>toggle_on</i
					>
					<i
						class="material-icons red toggle-btn"
						(click)="changeCrop2Opacity('show')"
						*ngIf="!this.map.crop2Opacity"
						>toggle_off</i
					>
				</div>
				<input
					class="slider"
					type="range"
					min="0"
					max="100"
					[(ngModel)]="crop2Opacity"
					(ngModelChange)="opacityChangeCrop2($event)"
				/>
			</div>

			<div class="divs">
				<div
					*ngIf="bee.totals.length >= 0"
					fxLayout="row"
					fxLayoutAlign=" center"
				>
					<div class="legend" fxLayout="row" fxLayoutAlign=" center">
						<span class="circle circleOne"></span>
						<p>Sorghum</p>
					</div>
					<div class="legend" fxLayout="row" fxLayoutAlign=" center">
						<span class="circle circleTwo"></span>
						<p>Cotton</p>
					</div>
					<div class="legend" fxLayout="row" fxLayoutAlign=" center">
						<span class="circle circleFour"></span>
						<p>Other</p>
					</div>
				</div>

				<div *ngIf="bee.totals.length >= 0">
					<p>NDVI Scale</p>
					<img
						class="ndvi-scale"
						src="https://agtuary-media-public.s3.ap-southeast-2.amazonaws.com/ndvi.png"
					/>
				</div>
			</div>
		</div>

		<div class="slide-container" *ngIf="this.map.toolPad === 'region'">
			<div
				class="top-bar"
				fxLayout="row"
				fxLayoutAlign="space-between center"
			>
				<h4>Regions list</h4>
				<i class="material-icons cross" (click)="close()">close</i>
			</div>
			<app-region></app-region>
		</div>

		<div class="slide-container" *ngIf="this.map.toolPad === 'form'">
			<div
				class="top-bar"
				fxLayout="row"
				fxLayoutAlign="space-between center"
			>
				<h4>Add paddock</h4>
				<i class="material-icons cross" (click)="close()">close</i>
			</div>

			<div
				class="navigation"
				fxLayout="row"
				fxLayoutAlign="space-around center"
			>
				<p
					[class.active-nav]="this.map.form === 'crop'"
					(click)="this.map.form = 'crop'"
				>
					Crop
				</p>
				<p
					[class.active-nav]="this.map.form === 'pasture'"
					(click)="this.map.form = 'pasture'"
				>
					Pasture
				</p>
			</div>

			<!-- form for creating a new paddock -->
			<app-paddock-form></app-paddock-form>
		</div>
	</div>
</div>
