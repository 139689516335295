import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class EmpireService {
	public locationMapIcon =
		'https://cdn0.iconfinder.com/data/icons/gpsmapicons/red/gpsmapicons01.png';
	public dropPin =
		'https://cdn0.iconfinder.com/data/icons/locationicons/push_pin.png';

	constructor() {}
}
