<div class="navigation" fxLayout="row" fxLayoutAlign="space-around center">
	<p
		[class.active-nav]="this.listDisplay === 'crop'"
		(click)="toggleTab('crop')"
	>
		Crop
	</p>
	<p
		[class.active-nav]="this.listDisplay === 'pasture'"
		(click)="toggleTab('pasture')"
	>
		Pasture
	</p>
</div>

<div class="counter">
	<p *ngIf="listDisplay === 'crop'">
		{{ cropPaddocksData.length }}
		{{ cropPaddocksData.length == 1 ? "paddock" : "paddocks" }}
	</p>

	<p *ngIf="listDisplay === 'pasture'">
		{{ pasturePaddocksData.length }}
		{{ pasturePaddocksData.length == 1 ? "paddock" : "paddocks" }}
	</p>
</div>

<!-- crop -->
<div class="table-container" *ngIf="listDisplay === 'crop'">
	<div *ngIf="displayList">
		<div class="titles" fxLayout="row" fxLayoutAlign="space-between center">
			<i class="material-icons green icon" (click)="displayAllCrop()">{{
				this.map.selectAllCrop
					? "radio_button_checked"
					: "radio_button_unchecked"
			}}</i>
			<!-- <i class="material-icons green">done</i> -->
			<p>Name</p>
			<p>Type</p>
			<p
				*ngIf="
					this.app.deviceSize == 'tablet' ||
					this.app.deviceSize == 'desktop'
				"
			>
				Growth
			</p>
			<p
				*ngIf="
					this.app.deviceSize == 'tablet' ||
					this.app.deviceSize == 'desktop'
				"
			>
				Area ha
			</p>
			<p
				*ngIf="
					this.app.deviceSize == 'tablet' ||
					this.app.deviceSize == 'desktop'
				"
			>
				Updated
			</p>
			<i class="material-icons white icon-hidden">arrow_forward_ios</i>
		</div>

		<div class="table">
			<div class="message">
				<p class="greytext" *ngIf="cropPaddocksData.length === 0">
					You have no paddocks listed.
				</p>
			</div>
			<div *ngFor="let pad of cropPaddocksData" class="items">
				<div
					class="row"
					fxLayout="row"
					fxLayoutAlign="space-between center"
				>
					<i
						class="material-icons green icon"
						(click)="this.map.visibilityControl(pad)"
						>{{
							this.map.visiblePaddocks[pad.id]
								? "visibility"
								: "visibility_off"
						}}</i
					>
					<!-- <i class="material-icons green" style="min-width: 30px">{{
						pad.verified ? "done" : ""
					}}</i> -->
					<p>
						{{ pad.values.name || "-" | titlecase | slice: 0:11 }}
					</p>

					<p>{{ pad.values.crop }}</p>
					<p
						*ngIf="
							this.app.deviceSize == 'tablet' ||
							this.app.deviceSize == 'desktop'
						"
					>
						{{ pad.values.growth ? pad.values.growth : "-" }}
					</p>
					<p
						*ngIf="
							this.app.deviceSize == 'tablet' ||
							this.app.deviceSize == 'desktop'
						"
					>
						{{ pad.values.area ? pad.values.area : "-" }}
					</p>
					<p
						*ngIf="
							this.app.deviceSize == 'tablet' ||
							this.app.deviceSize == 'desktop'
						"
					>
						{{ pad.created | date: "mediumDate" }}
					</p>
					<i
						class="material-icons green icon"
						(click)="displayPaddockInfo(pad)"
						>arrow_forward_ios</i
					>
				</div>
			</div>
		</div>
	</div>

	<app-paddock-information
		[pasturePaddock]="pasturePaddock"
		[cropPaddock]="cropPaddock"
		(displayPaddockList)="displayPaddockList()"
	>
	</app-paddock-information>

	<!-- form for editing a paddock -->
	<app-paddock-form
		*ngIf="this.map.form === 'crop'"
		[pasturePaddock]="pasturePaddock"
		[cropPaddock]="cropPaddock"
	></app-paddock-form>
</div>

<!-- pasture -->
<div class="table-container" *ngIf="listDisplay === 'pasture'">
	<div *ngIf="displayList">
		<div class="titles" fxLayout="row" fxLayoutAlign="space-between center">
			<i
				class="material-icons green icon"
				(click)="displayAllPasture()"
				>{{
					this.map.selectAllPasture
						? "radio_button_checked"
						: "radio_button_unchecked"
				}}</i
			>
			<p>Name</p>
			<p>Type</p>
			<p>Count</p>
			<p
				*ngIf="
					this.app.deviceSize == 'tablet' ||
					this.app.deviceSize == 'desktop'
				"
			>
				Capacity
			</p>
			<p
				*ngIf="
					this.app.deviceSize == 'tablet' ||
					this.app.deviceSize == 'desktop'
				"
			>
				Area ha
			</p>
			<p
				*ngIf="
					this.app.deviceSize == 'tablet' ||
					this.app.deviceSize == 'desktop'
				"
			>
				Updated
			</p>
			<i class="material-icons white icon-hidden">arrow_forward_ios</i>
		</div>

		<div class="table">
			<div class="message">
				<p class="greytext" *ngIf="pasturePaddocksData.length === 0">
					You have no paddocks listed.
				</p>
			</div>
			<div *ngFor="let pad of pasturePaddocksData" class="items">
				<div
					class="row"
					fxLayout="row"
					fxLayoutAlign="space-between center"
					*ngIf="pad.cropType === 'pasture'"
				>
					<i
						class="material-icons green icon"
						(click)="this.map.visibilityControl(pad)"
						>{{
							this.map.visiblePaddocks[pad.id]
								? "visibility"
								: "visibility_off"
						}}</i
					>
					<p *ngIf="pad.values.name">
						{{ pad.values.name | titlecase | slice: 0:7 }}...
					</p>
					<p *ngIf="!pad.values.name">-</p>

					<p
						*ngIf="
							this.app.deviceSize == 'tablet' ||
							this.app.deviceSize == 'desktop'
						"
					>
						{{ pad.values.livestock }}
					</p>
					<p *ngIf="this.app.deviceSize == 'mobile'">
						{{ pad.values.livestock | slice: 0:7 }}...
					</p>

					<p>
						{{
							pad.values.livestockCount
								? pad.values.livestockCount
								: "-"
						}}
					</p>
					<p
						*ngIf="
							this.app.deviceSize == 'tablet' ||
							this.app.deviceSize == 'desktop'
						"
					>
						{{
							pad.values.maxCapacity
								? pad.values.maxCapacity
								: "-"
						}}
					</p>
					<p
						*ngIf="
							this.app.deviceSize == 'tablet' ||
							this.app.deviceSize == 'desktop'
						"
					>
						{{ pad.values.area ? pad.values.area : "-" }}
					</p>
					<p
						*ngIf="
							this.app.deviceSize == 'tablet' ||
							this.app.deviceSize == 'desktop'
						"
					>
						{{ pad.created | date: "mediumDate" }}
					</p>
					<i
						class="material-icons green icon"
						(click)="displayPaddockInfo(pad)"
						>arrow_forward_ios</i
					>
				</div>
			</div>
		</div>
	</div>

	<app-paddock-information
		[pasturePaddock]="pasturePaddock"
		[cropPaddock]="cropPaddock"
		(displayPaddockList)="displayPaddockList()"
	>
	</app-paddock-information>

	<!-- form for editing a paddock -->
	<app-paddock-form
		*ngIf="this.map.form === 'pasture'"
		[pasturePaddock]="pasturePaddock"
		[cropPaddock]="cropPaddock"
	></app-paddock-form>
</div>
