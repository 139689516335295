<div class="form" *ngIf="this.app.mapControl == 'crop'">
	<!-- crop -->
	<div [formGroup]="cropData" *ngIf="this.map.form == 'crop'">
		<div class="selector">
			<label for="crop">Crop type<span>*</span></label>
			<select id="crop" formControlName="crop" (change)="selectCrop()">
				<option *ngFor="let crop of this.crops" [value]="crop">
					{{ crop }}
				</option>
			</select>
			<!-- error handling -->
			<div
				*ngIf="crop.invalid && (crop.dirty || crop.touched)"
				class="error"
			>
				<p *ngIf="crop.errors.required">Crop type is required.</p>
			</div>

			<input
				*ngIf="this.selectedCrop == 'Other'"
				type="text"
				id="crop"
				formControlName="otherCrop"
				placeholder="Type of crop"
			/>
		</div>

		<div class="selector">
			<label for="growth">Growth stage</label>
			<select id="growth" formControlName="growth">
				<option *ngFor="let stage of this.stages" [value]="stage">
					{{ stage }}
				</option>
			</select>
			<!-- error handling -->
			<div
				*ngIf="growth.invalid && (growth.dirty || growth.touched)"
				class="error"
			>
				<p *ngIf="growth.errors.required">Growth stage is required.</p>
			</div>
		</div>

		<div class="selector">
			<label for="season">Season</label>
			<select id="season" formControlName="seasonCrop">
				<option *ngFor="let season of this.seasons" [value]="season">
					{{ season }}
				</option>
			</select>
		</div>

		<div class="selector">
			<label for="year">Year</label>
			<select id="year" formControlName="yearCrop">
				<option *ngFor="let year of this.years" [value]="year">
					{{ year }}
				</option>
			</select>
		</div>

		<div class="selector">
			<label for="livestockCount">Paddock name</label>
			<input
				type="text"
				id="livestockCount"
				formControlName="nameCrop"
				placeholder="John's farm"
			/>
		</div>

		<div class="selector">
			<label for="livestockCount"
				>Does this area have a bad signal?</label
			>
			<div class="checkbox" fxLayout="row">
				<div
					class="check"
					fxLayout="row"
					fxLayoutAlign="center center"
					(click)="badArea('Yes')"
				>
					<i class="material-icons">{{
						this.badSignalArea == "yes"
							? "radio_button_checked"
							: "radio_button_unchecked"
					}}</i>
					<p>Yes</p>
				</div>
				<div
					fxLayout="row"
					fxLayoutAlign="center center"
					(click)="badArea('No')"
				>
					<i class="material-icons">{{
						this.badSignalArea == "no"
							? "radio_button_checked"
							: "radio_button_unchecked"
					}}</i>
					<p>No</p>
				</div>
			</div>
		</div>

		<p class="add" *ngIf="this.note == ''" (click)="this.note = 'crop'">
			{{ this.map.paddockEditMode ? "Edit note" : "Add note" }}
		</p>

		<div class="selector" *ngIf="this.note == 'crop'">
			<label for="growth">Notes</label>
			<textarea placeholder="type" formControlName="cropNote"></textarea>
		</div>

		<div class="error">{{ message }}</div>
		<div class="btn">
			<button class="solid-button" (click)="savePaddockData('crop')">
				{{ this.map.paddockEditMode ? "Update" : "Submit" }}
			</button>
		</div>
	</div>

	<!-- pasture -->
	<div [formGroup]="pastureData" *ngIf="this.map.form == 'pasture'">
		<div class="selector">
			<label for="pasture">Grass species<span>*</span></label>
			<select id="pasture" formControlName="pasture">
				<option *ngFor="let pasture of this.pastures" [value]="pasture">
					{{ pasture }}
				</option>
			</select>
			<!-- error handling -->
			<div
				*ngIf="pasture.invalid && (pasture.dirty || pasture.touched)"
				class="error"
			>
				<p *ngIf="pasture.errors.required">Grass type is required.</p>
			</div>
		</div>

		<div class="selector">
			<label for="livestock">Livestock type<span>*</span></label>
			<select
				id="livestock"
				formControlName="livestock"
				(change)="selectLivestock()"
			>
				<option
					*ngFor="let livestock of this.livestocks"
					[value]="livestock"
				>
					{{ livestock }}
				</option>
			</select>
			<!-- error handling -->
			<div
				*ngIf="
					livestock.invalid && (livestock.dirty || livestock.touched)
				"
				class="error"
			>
				<p *ngIf="livestock.errors.required">
					Livestock type is required.
				</p>
			</div>

			<input
				*ngIf="this.selectedLivestock == 'Other'"
				type="text"
				id="crop"
				formControlName="otherLivestock"
				placeholder="Type of livestock"
			/>
		</div>

		<div class="selector">
			<label for="season">Season</label>
			<select id="season" formControlName="seasonPasture">
				<option *ngFor="let season of this.seasons" [value]="season">
					{{ season }}
				</option>
			</select>
		</div>

		<div class="selector">
			<label for="year">Year</label>
			<select id="year" formControlName="yearPasture">
				<option *ngFor="let year of this.years" [value]="year">
					{{ year }}
				</option>
			</select>
		</div>

		<div class="selector">
			<label for="livestockCount">Paddock name</label>
			<input
				type="text"
				id="livestockCount"
				formControlName="namePasture"
				placeholder="John's farm"
			/>
		</div>

		<div class="selector">
			<label for="livestockCount">What is the head count?</label>
			<input
				type="number"
				id="livestockCount"
				formControlName="livestockCount"
				placeholder="e.g. 320"
			/>
		</div>

		<div class="selector">
			<label for="livestockCount">Is this at max DSE?</label>
			<div class="checkbox" fxLayout="row">
				<div
					class="check"
					fxLayout="row"
					fxLayoutAlign="center center"
					(click)="checkbox('Yes')"
				>
					<i class="material-icons">{{
						this.maxDse == "yes"
							? "radio_button_checked"
							: "radio_button_unchecked"
					}}</i>
					<p>Yes</p>
				</div>
				<div
					fxLayout="row"
					fxLayoutAlign="center center"
					(click)="checkbox('No')"
				>
					<i class="material-icons">{{
						this.maxDse == "no"
							? "radio_button_checked"
							: "radio_button_unchecked"
					}}</i>
					<p>No</p>
				</div>
			</div>
		</div>

		<div class="selector" *ngIf="this.maxCap == 'No'">
			<label for="maxCapacity">What is the max DSE?</label>
			<input
				type="number"
				id="maxCapacity"
				formControlName="maxCapacity"
				placeholder="e.g. 450"
			/>
		</div>

		<p class="add" *ngIf="this.note == ''" (click)="this.note = 'pasture'">
			{{ this.map.paddockEditMode ? "Edit note" : "Add note" }}
		</p>

		<div class="selector" *ngIf="this.note == 'pasture'">
			<label for="growth">Notes</label>
			<textarea
				placeholder="type"
				formControlName="pastureNote"
			></textarea>
		</div>

		<div class="error">{{ message }}</div>
		<div class="btn">
			<button class="solid-button" (click)="savePaddockData('pasture')">
				{{ this.map.paddockEditMode ? "Update" : "Submit" }}
			</button>
		</div>
	</div>
</div>
<!-- property/parcel purchase form -->
<div class="property-form" *ngIf="this.app.mapControl == 'parcels'">
	<div class="selector" *ngIf="!this.bee.ctx.project && !checkingOut">
		<label for="clientName">Client business name</label>
		<input
			type="text"
			id="clientName"
			placeholder="Delight dairy farms"
			[(ngModel)]="newClientName"
		/>
	</div>
	<div *ngIf="!checkingOut">
		<div class="selector">
			<label for="propertyName">Property name</label>
			<input
				type="text"
				id="propertyName"
				placeholder="North west cattle"
				[(ngModel)]="newPropertyName"
			/>
		</div>
		<app-waiting-icon
			[visible]="app.paymentPreview == undefined"
		></app-waiting-icon>
		<div
			*ngIf="app.paymentPreview != undefined"
			fxLayout="column"
			fxLayoutAlign="center start"
		>
			<h4>Property Report</h4>
			<div class="list" fxLayout="column" fxLayoutAlign="start start">
				<i
					class="material-icons red icon close"
					(click)="clearParcels()"
					>close</i
				>
				<div
					*ngFor="let area of parcelDetails.area; let i = index"
					class="item"
					fxLayout="row"
					fxLayoutAlign="space-between center"
				>
					<div fxLayout="row" fxLayoutAlign="center center">
						<i class="material-icons green">check_box</i>
						<p>Property {{ i + 1 }}</p>
					</div>
					<p>
						<span>{{ area | number: "1.2-2" }}</span> ha
					</p>
				</div>
			</div>
			<p>
				{{ parcelDetails.count == 1 ? "Property" : "Properties" }} count
				<span>{{ parcelDetails.count }}</span>
			</p>
			<p>
				Total area
				<span>{{ parcelDetails.total | number: "1.2-2" }}</span>
				ha
			</p>
		</div>
		<div class="details" fxLayout="column" fxLayoutAlign="center start">
			<ng-container *ngIf="!this.bee.isPremiumTeam">
				<p>Purchase the following data set for <span>$500</span></p>
			</ng-container>
			<ng-container *ngIf="this.bee.isPremiumTeam">
				<p>Generate the following data</p>
			</ng-container>
			<div class="item" fxLayout="row" fxLayoutAlign="center center">
				<i class="material-icons green">looks_one</i>
				<p>Geographical Property Information</p>
			</div>
			<div class="item" fxLayout="row" fxLayoutAlign="center center">
				<i class="material-icons green">looks_two</i>
				<p>Rainfall History</p>
			</div>
			<div class="item" fxLayout="row" fxLayoutAlign="center center">
				<i class="material-icons green">looks_3</i>
				<p>Land Use and Productivity</p>
			</div>
			<div class="item" fxLayout="row" fxLayoutAlign="center center">
				<i class="material-icons green">looks_4</i>
				<p>Soil Profile Summary</p>
			</div>
		</div>

		<div class="btn" fxLayout="column" fxLayoutAlign="center start">
			<div class="error">{{ message }}</div>
			<button class="solid-button" (click)="saveClientdata()">
				Continue
			</button>
		</div>
	</div>
	<div class="check-out" *ngIf="checkingOut">
		<div>
			<p>
				Client
				<span>{{
					newClientName ? newClientName : this.bee.ctx.project.name
				}}</span>
			</p>
			<p>
				Property <span>{{ newPropertyName }}</span>
			</p>
		</div>
		<div fxLayout="column" fxLayoutAlign="center center">
			<p>Click on checkout</p>
			<p>or scan the QR code</p>
			<button class="solid-button" (click)="openCheckout()">
				Checkout
			</button>
			<div fxLayout="column" fxLayoutAlign="center center"></div>
		</div>
		<div fxLayout="column" fxLayoutAlign="center center">
			<p>Confirm when the payment is complete</p>
			<button class="border-button" (click)="this.checkReport()">
				Finalise
			</button>
		</div>
	</div>
</div>
