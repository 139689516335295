import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PaddockFormComponent } from "../modules/components/paddock-form/paddock-form.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { WaitingIconComponent } from "../modules/components/waiting-icon/waiting-icon.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { PaddockComponent } from "../modules/components/paddock/paddock.component";
import { PaddockInformationComponent } from "../modules/components/paddock-information/paddock-information.component";
import { PopupMessageComponent } from "../modules/components/popup-message/popup-message.component";
import { MaterialModule } from "./material.module";
import { TooltipComponent } from "../modules/components/tooltip/tooltip.component";
import { RegionComponent } from "../modules/components/region/region.component";

@NgModule({
	declarations: [
		RegionComponent,
		PaddockFormComponent,
		WaitingIconComponent,
		PaddockComponent,
		PaddockInformationComponent,
		PopupMessageComponent,
		TooltipComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		FlexLayoutModule,
		ReactiveFormsModule,
		MaterialModule,
	],
	exports: [
		RegionComponent,
		PaddockFormComponent,
		WaitingIconComponent,
		PaddockComponent,
		PaddockInformationComponent,
		PopupMessageComponent,
		TooltipComponent,
	],
})
export class SharedModule {}
