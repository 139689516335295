<div class="header-popup" fxLayout="row" fxLayoutAlign="space-between center">
	<h4>{{ header }}</h4>
</div>

<p *ngIf="message" class="section-helper">
	{{ message }}
</p>

<div class="btn-container" fxLayout="row" fxLayoutAlign="space-between center">
	<button
		class="border-button"
		*ngFor="let o of options"
		(click)="doOption(o)"
	>
		{{ buttons[o] }}
	</button>
</div>
