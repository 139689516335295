import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	CanActivate,
} from '@angular/router';
import { BeeService } from '../services/bee/bee.service';

@Injectable({
	providedIn: 'root',
})
export class AuthPublicGuard implements CanActivate {
	constructor(private router: Router, private bee: BeeService) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const isAuthenticated = this.bee.authenticated; // && !this.bee.internal;
		if (!isAuthenticated) {
			return this.router.parseUrl('/login');
		}
		return isAuthenticated;
	}
}
