<!-- crop info -->
<div *ngIf="this.map.displayInfo === 'crop'" class="displayInfo">
	<div fxLayout="row" fxLayoutAlign="space-between center">
		<app-tooltip
			(click)="displayList()"
			content="Back"
			icon="arrow_back_ios_new"
			class="right"
			color="green icon"
		></app-tooltip>

		<div fxLayout="row" fxLayoutAlign="space-between center">
			<app-tooltip
				(click)="editPaddockDetails('crop')"
				content="Edit"
				icon="edit"
				class="bottom"
				color="green icon"
			></app-tooltip>
			<app-tooltip
				(click)="this.map.editPaddockShape(cropPaddock[0].id)"
				content="Shape"
				icon="rounded_corner"
				class="bottom"
				color="green icon"
			></app-tooltip>
			<app-tooltip
				(click)="deletePaddock(cropPaddock[0].id)"
				content="Delete"
				icon="remove_circle"
				class="bottom"
				color="red icon"
			></app-tooltip>
		</div>
	</div>
	<div *ngFor="let item of cropPaddock" class="details">
		<p>Paddock information</p>
		<div class="item">
			<p class="title">Paddock name</p>
			<p>{{ item.values.name ? item.values.name : "-" }}</p>
		</div>
		<div class="item">
			<p class="title">Crop type</p>
			<p>
				{{ item.values.crop ? item.values.crop : "-" }}
			</p>
		</div>
		<div class="item">
			<p class="title">Growth</p>
			<p>
				{{ item.values.growth ? item.values.growth : "-" }}
			</p>
		</div>
		<div class="item">
			<p class="title">Area ha</p>
			<p>
				{{ item.values.area ? item.values.area : "-" }}
			</p>
		</div>
		<div class="item">
			<p class="title">Season</p>
			<p>
				{{ item.values.season ? item.values.season : "-" }}
			</p>
		</div>
		<div class="item">
			<p class="title">Year</p>
			<p>
				{{ item.values.year ? item.values.year : "-" }}
			</p>
		</div>
		<div class="item">
			<p class="title">Date created</p>
			<p>{{ item.created | date: "medium" }}</p>
		</div>
		<div class="item">
			<p class="title">Note</p>
			<p>{{ item.values.note ? item.values.note : "-" }}</p>
		</div>
	</div>
</div>

<!-- pasture info -->
<div *ngIf="this.map.displayInfo === 'pasture'" class="displayInfo">
	<div fxLayout="row" fxLayoutAlign="space-between center">
		<app-tooltip
			(click)="displayList()"
			content="Back"
			icon="arrow_back_ios_new"
			class="right"
			color="green icon"
		></app-tooltip>

		<div fxLayout="row" fxLayoutAlign="space-between center">
			<app-tooltip
				(click)="editPaddockDetails('pasture')"
				content="Edit"
				icon="edit"
				class="bottom"
				color="green icon"
			></app-tooltip>
			<app-tooltip
				(click)="this.map.editPaddockShape(cropPaddock[0].id)"
				content="Shape"
				icon="rounded_corner"
				class="bottom"
				color="green icon"
			></app-tooltip>
			<app-tooltip
				(click)="deletePaddock(cropPaddock[0].id)"
				content="Delete"
				icon="remove_circle"
				class="bottom"
				color="red icon"
			></app-tooltip>
		</div>
	</div>
	<div *ngFor="let item of pasturePaddock" class="details">
		<p>Paddock information</p>
		<div class="item">
			<p class="title">Paddock name</p>
			<p>{{ item.values.name ? item.values.name : "-" }}</p>
		</div>
		<div class="item">
			<p class="title">Grass species</p>
			<p>
				{{ item.values.pasture ? item.values.pasture : "-" }}
			</p>
		</div>
		<div class="item">
			<p class="title">Livestock type</p>
			<p>
				{{ item.values.livestock ? item.values.livestock : "-" }}
			</p>
		</div>
		<div class="item">
			<p class="title">Livestock count</p>
			<p>
				{{
					item.values.livestockCount
						? item.values.livestockCount
						: "-"
				}}
			</p>
		</div>
		<div class="item">
			<p class="title">Max capacity</p>
			<p>
				{{ item.values.maxCapacity ? item.values.maxCapacity : "-" }}
			</p>
		</div>
		<div class="item">
			<p class="title">Area ha</p>
			<p>
				{{ item.values.area ? item.values.area : "-" }}
			</p>
		</div>
		<div class="item">
			<p class="title">Season</p>
			<p>
				{{ item.values.season ? item.values.season : "-" }}
			</p>
		</div>
		<div class="item">
			<p class="title">Year</p>
			<p>
				{{ item.values.year ? item.values.year : "-" }}
			</p>
		</div>
		<div class="item">
			<p class="title">Date created</p>
			<p>{{ item.created | date: "medium" }}</p>
		</div>
		<div class="item">
			<p class="title">Note</p>
			<p>{{ item.values.note ? item.values.note : "-" }}</p>
		</div>
	</div>
</div>
