import { Component, OnInit } from "@angular/core";
import { BeeService } from "@src/app/core/services/bee/bee.service";
import { AppService } from "@src/app/core/services/app.service";
import { EmpireService } from "@src/app/core/services/empire.service";

@Component({
	selector: "app-region",
	templateUrl: "./region.component.html",
	styleUrls: ["./region.component.scss"],
})
export class RegionComponent implements OnInit {
	csv = false;
	region: "table" | "chart" = "table";

	sorghum: number[] = [];
	cotton: number[] = [];
	other: number[] = [];
	title: string[] = [];

	constructor(
		public bee: BeeService,
		public app: AppService,
		public empire: EmpireService
	) {}

	ngOnInit(): void {
		this.bee.totals.forEach((reg) => {
			if (reg.name !== "Total") {
				this.sorghum.push(Number(reg.sorghum.toFixed(2)));
				this.cotton.push(Number(reg.cotton.toFixed(2)));
				this.other.push(Number(reg.other.toFixed(2)));
				this.title.push(reg.name.concat(" ").concat(reg.state));
			}
		});
	}

	ngAfterViewInit() {}

	toggleChart(type) {
		this.region = type;

		setTimeout(() => {
			this.ngAfterViewInit();
		}, 50);
	}

	copyCSV() {
		this.csv = true;

		let t =
			"Region,State,Sorghum (kHa), Sorghum Harvested (kHa), Cotton (kHa), Cotton Harvested (kHa), Other (kHa), Other Harvested (kHa), Total (kHa), Total Harvested (kHa)\n";
		for (let a of this.bee.totals) {
			const h = this.bee.harvest.filter((h) => h.name == a.name)[0];
			t += `${a["name"]},${a["state"]},${a["sorghum"]},${h["sorghum"]},${a["cotton"]},${h["cotton"]},${a["other"]},${h["other"]},${a["total"]},${h["total"]}\n`;
		}
		navigator.clipboard.writeText(t);

		setTimeout(() => {
			this.csv = false;
		}, 3000);
	}

	getLabel(r: string) {
		if (r == "Goondiwindi") {
			return "Goondiwi...";
		}

		if (r == "Central Queensland") {
			return "Central Queens...";
		}

		return r;
	}
}
