// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	amplitude: '1845aca3a446b03dc68e916499139bdd',
	bee: 'https://api.agtuary.app',
	fauna_client: '',
	version: '0.1.0',
	local: false,
	stripe: 'pk_live_51HbeM4Gz16IFboibgDlnJrziWF2dXyoLLaJyXIyldyIYUmNZAbstIK0rSefndzmqNq91uhKmAfToh8jWdtBw461j00fbLVH5ER',
	autologinEmail: 'matthew@agtuary.com',
	autologinPassword: 'dzP734L7',
	faunaEmbed: 'fnAEXyeCApACUE6c8ZyARQgTz24nq5YLUOJULaaA',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
