import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import { Injectable } from "@angular/core";
import { IPage, IToastMessage } from "@core/models/app.model";
import { environment } from "@src/environments/environment";
import { Observable, Subject } from "rxjs";
import { featureFlags } from "../models/app/features";
import {
	IMenuLink,
	Journey,
	bottomLinks,
	mainLinks,
} from "../models/app/links";
import { NavigationEnd, Router } from "@angular/router";
import { userBasePath } from "@src/app/app-routing.module";

interface IAppState {
	profile: IPage;
	finance: IPage;
}

interface IAppStatus {
	loading: boolean;
	loaded: boolean;
	submitting: boolean;
	submitted: boolean;
}

@Injectable({
	providedIn: "root",
})
export class AppService {
	public get mainLinks(): IMenuLink[] {
		return this._linksMain.filter(
			(x) => !!featureFlags.menu[x.route] || x.enabled
		);
	}
	public get bottomLinks(): IMenuLink[] {
		return this._linksBottom.filter((x) => x.enabled);
	}

	constructor(private router: Router) {
		this.determineDevice();

		if (router.url.includes("/internal")) {
			this.base = "/internal";
			this.mainLink = {
				Dashboard: "dashboard",
				"Customer reviews": "reviews",
				"New applications": "loans",
			};
		}

		this.router.events.subscribe((e: any) => {
			if (!e || !(e instanceof NavigationEnd)) {
				return;
			}
			if (e.url.includes("profile")) {
				this.mainLink[0].active = false;
				this.mainLink[1].active = true;
			}
		});

		// TODO: dont base off url, base off login auth guard
		// if app.accounttype == 'user' (or admin)
		if (userBasePath) {
			this.base = `/${userBasePath}`;
		}

		this.linkKeys = Object.keys(this.mainLinks);
	}
	public message: IToastMessage;

	@Input() base = "";
	@Input() mainLink: any = {};
	@Input() bottomLink: any = {};
	@Input() topLink: any = {};
	linkKeys = [];

	//#region not in use
	public review = false;
	public progress = false;
	public todo = false;
	//#endregion

	public job:
		| "mainBoard"
		| "jobs"
		| "jobBoard"
		| "taskBoard"
		| "createJob"
		| "webform"
		| "completed"
		| "review"
		| "" = "";

	public task: "taskManager" | "taskEditor" | "taskInfo" | "" = "";

	// important variables
	public landActiveItems: string[] = [];

	public isWidget = false;
	public isWidgetControl = true;

	public mainApp = true;
	public activeMapID: string;
	public reportActive = false;
	public tooltip = false;

	public minute = 6;
	public seconds = 60;

	public selectedState = "";
	public zoomLevel: number = null;

	// controling page
	public mapControl: "parcels" | "macro" | "crop" | "" = "";

	// controling portfolio and clients
	public journey: Journey = "";
	public subJourney: "parcels" | "property" | "details" | "" = "";
	public editingClient = true;
	public tagsToAdd: string[] = [];
	public propertyStatistics = false;

	public sidebarWindow: "onboarding" | "features" | "news" | "" = "";
	public messageWindow: "welcome" | "subscription" | "" = "";
	public onboardingIcon: "one" | "two" | "three" | "four" | "five" | "" = "";

	public deviceSize: "mobile" | "tablet" | "desktop";
	public slider:
		| "client"
		| "taskInfo"
		| "paddockInfo"
		| "team"
		| "invite-user"
		| "" = "";

	public popMessage:
		| "form"
		| "connect"
		| "save"
		| "update"
		| "pin"
		| "confirm"
		| "delete"
		| "distance"
		| "" = "";

	public medalImage = null;

	public features = featureFlags;
	public mainClient: boolean;

	public backgroundCss = "linear-gradient(#FCFBF8, #FCFBF8)";
	public backgroundOpacity = 0.0;

	public collapseSidebar = false;

	public state: IAppState = {
		profile: {
			dirty: false,
		},
		finance: {
			dirty: false,
		},
	};

	public selectedParcelsDict: any = {};
	public customParcelGeojson: any = undefined;
	public paymentPreview: {
		area: number;
		areas: { [id: string]: number };
		center: number[];
		geocode: any;
		geoson: any;
		id: string;
		price: number;
	} = undefined;

	public loading = {
		messages: false,
		portal: false,
		blobs: false,
		user: false,
		chart: false,
		report: {
			loading: false,
			ready: false,
			message: false,
		},
		file: false,
	};

	public submitting = {
		settings: false,
		clients: false,
		messages: false,
		portal: false,
		task: false,
	};

	private _linksMain: IMenuLink[] = mainLinks;
	private _linksBottom: IMenuLink[] = bottomLinks;

	public resize$: Subject<any> = new Subject();

	public onboardingTour = [
		{
			title: "Portfolio",
			text: [
				"Manage clients and properties and track their performance using data analytics.",
			],
			display: true,
		},
		{
			title: "Add properties",
			text: [
				"To access data analytics, add a property and assign a client’s name.",
				"Add more properties to an existing client from the Client List.",
				"Manage multiple properties by clicking on any client’s name.",
			],
			display: false,
			journey: () => {
				this.navigateMainLinks("portfolio", 0);
				this.onboardingIcon = "two";
			},
		},
		{
			title: "Control panel",
			text: [
				"Locate any property by clicking directly on the map or entering the address into the search bar.",
				"To add it to your portfolio, select the property and assign it a client’s name.",
				"To manage multiple properties, add them to an existing client from the clients list.",
			],
			display: false,
		},
		{
			title: "Property report",
			text: [
				"Click on any property from the Portfolio to access all the recorded details.",
				"To get a PDF with a Property Report, select “Download” from the Documents panel.",
			],
			display: false,
			journey: () => {
				this.subJourney = "details";
				this.onboardingIcon = "four";
			},
		},
		{
			title: "Data analytics",
			text: [
				"Select any of the added properties to access data visualisations.",
				"Explore and assess the property’s performance over time.",
			],
			display: false,
		},
	];

	toggleSideMenu() {
		this.collapseSidebar = !this.collapseSidebar;
	}

	determineDevice() {
		const phone =
			window.matchMedia(
				"only screen and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2)"
			).matches ||
			window.matchMedia(
				"only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3)"
			).matches ||
			window.matchMedia(
				"only screen and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 1)"
			).matches;
		const tablet = window.matchMedia(
			"only screen and (min-device-width: 720px) and (max-device-width: 1024px)"
		).matches;
		const desktop = !phone && !tablet;

		if (tablet) {
			this.deviceSize = "tablet";
			this.collapseSidebar = true;
		}
		if (phone) {
			this.deviceSize = "mobile";
			this.collapseSidebar = true;
		}
		if (desktop) {
			this.deviceSize = "desktop";
			this.collapseSidebar = false;
		}
	}

	isGNC() {
		return window.location.origin.includes("graincorp.agtuary.app");
	}

	showMessage(m: IToastMessage) {
		this.message = m;
		this.message.visible = true;

		setTimeout(() => {
			this.message.visible = false;
		}, 3000);
	}

	closeMessage() {
		this.message.visible = false;
	}

	public setFlags(
		flags: { hasRole: boolean; mainClient: boolean },
		override: boolean = false
	) {
		const o = override;
		featureFlags.menu.clients = false; // || o || (flags.hasRole && !flags.mainClient);
		featureFlags.menu.dashboard = false; // || o || (flags.hasRole && !flags.mainClient);
		featureFlags.menu.map = o || flags.mainClient;
		featureFlags.menu.portfolio = o || flags.hasRole || flags.mainClient;
		featureFlags.menu.region = o || true;
		featureFlags.menu.team = false;
		featureFlags.menu.dev = false;

		this.mainClient = featureFlags.menu.map;

		if (featureFlags.menu.map) {
			mainLinks[0].active = true;
			mainLinks[0].active = false;
		} else {
			// mainLinks[2].active = true;
			// mainLinks[1].active = false;
		}
	}

	public nextStep(step) {
		if (step == this.onboardingTour.length) {
			this.sidebarWindow == "";
			return;
		}

		this.onboardingTour.forEach((item, index) => {
			if (step + 1 == index) {
				item.display = true;
				if ("journey" in item) {
					item.journey();
				}
			} else {
				item.display = false;
			}
		});
	}

	public navigateMainLinks(route: string, i: number) {
		this.journey = "portfolio";
		this.subJourney = "";
		this.journey = route;

		this.bottomLinks.forEach((link) => {
			link.active = false;
		});

		this.mainLinks.forEach((link, index) => {
			if (index === i) {
				link.active = true;
				document
					.querySelectorAll(".mainIcon")
					[i].classList.add("green");
				[].forEach.call(
					document.querySelectorAll(".bottomIcon"),
					function (tag) {
						tag.classList.remove("green");
					}
				);
			} else {
				link.active = false;
				[].filter.call(
					document.querySelectorAll(".mainIcon"),
					function (tag, index) {
						if (index !== i) {
							tag.classList.remove("green");
						}
					}
				);
			}
		});

		if (route) {
			this.router.navigate([`${this.base}/${route}`]);
		}
	}

	public navigateBottomLink(route: string, i: number) {
		this.mainLinks.forEach((link) => {
			link.active = false;
		});

		this.bottomLinks.forEach((link, index) => {
			if (index === i) {
				link.active = true;
				document
					.querySelectorAll(".bottomIcon")
					[i].classList.add("green");
				[].forEach.call(
					document.querySelectorAll(".mainIcon"),
					function (tag) {
						tag.classList.remove("green");
					}
				);
			} else {
				link.active = false;
				[].filter.call(
					document.querySelectorAll(".bottomIcon"),
					function (tag, index) {
						if (index !== i) {
							tag.classList.remove("green");
						}
					}
				);
			}
		});

		if (route) {
			if (route == "logout") {
				// this.bee.logout();
				this.router.navigate(["/login"]);
			} else {
				this.router.navigate([`${this.base}/${route}`]);
			}
		}
	}
}
