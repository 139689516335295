<div
	class="header"
	fxLayout="row"
	fxLayoutAlign="space-between center"
	*ngIf="this.app.deviceSize == 'mobile'"
>
	<div fxLayout="column" fxLayoutAlign=" center">
		<a
			href="https://www.agtuary.com/"
			target="_blank"
			fxLayoutAlign=" center"
		>
			<img src="assets/logo-black.svg" />
		</a>
	</div>

	<div fxLayout="column" fxLayoutAlign=" center">
		<a
			*ngIf="app.isGNC()"
			href="https://www.graincorp.com.au/"
			target="_blank"
			fxLayoutAlign=" center"
		>
			<img src="assets/graincorp.png" />
		</a>
	</div>
</div>

<div
	class="header"
	fxLayout="row"
	fxLayoutAlign="space-between center"
	*ngIf="this.app.deviceSize == 'tablet' || this.app.deviceSize == 'desktop'"
>
	<div fxLayout="row" fxLayoutAlign=" end" *ngIf="app.isGNC()">
		<a
			href="https://www.graincorp.com.au/"
			target="_blank"
			fxLayoutAlign=" center"
		>
			<img src="assets/graincorp.png" />
		</a>
		<p>Crop Analysis</p>
	</div>

	<div fxLayout="row" fxLayoutAlign=" end">
		<p *ngIf="app.isGNC()">Powered By</p>
		<a
			href="https://www.agtuary.com/"
			target="_blank"
			fxLayoutAlign=" center"
		>
			<img src="assets/logo-black.svg" />
		</a>
	</div>
</div>
