import { Component, OnInit } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { AppService } from "@src/app/core/services/app.service";
import { BeeService } from "@src/app/core/services/bee/bee.service";
import { environment } from "@src/environments/environment";
import { OnboardingService } from "@src/app/core/services/onboarding.service";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
	constructor(
		public bee: BeeService,
		public app: AppService,
		public onboarding: OnboardingService,
		private route: ActivatedRoute
	) {}

	get email() {
		return this.loginForm.get("email");
	}
	get password() {
		return this.loginForm.get("password");
	}
	get firstName() {
		return this.signupForm.get("firstName");
	}
	get lastName() {
		return this.signupForm.get("lastName");
	}
	get company() {
		return this.signupForm.get("company");
	}
	get emailSignup() {
		return this.signupForm.get("emailSignup");
	}
	get passwordSignup() {
		return this.signupForm.get("passwordSignup");
	}
	get accessCode() {
		return this.signupForm.get("accessCode");
	}
	get role() {
		return this.signupForm.get("role");
	}
	get terms() {
		return this.signupForm.get("terms");
	}
	get passwordEmail() {
		return this.passwordForm.get("passwordEmail");
	}
	get other() {
		return this.signupForm.get("other");
	}
	loginForm: FormGroup;
	signupForm: FormGroup;
	passwordForm: FormGroup;

	message = "";
	mode: "login" | "signup" | "password" = "login";
	navMode: "login" | "signup" = "login";
	userRoles:
		| "farmer"
		| "banker"
		| "advisor"
		| "manager"
		| "valuer"
		| "other"
		| "" = "";

	private i = false;
	private route$: Subscription;
	private defaultEmail = "";
	private action = "";
	public sentPasswordReset = false;
	public sendingPasswordReset = false;
	public title = "Welcome to Agtuary";

	tempRole = "";

	ngOnInit() {
		const emailPattern =
			"^[aA-zZ0-9._%+-]+@[aA-zZ0-9]{1,}[aA-zZ0-9-.]*.[a-z]{2,4}$";
		this.loginForm = new FormGroup({
			email: new FormControl(this.defaultEmail, [
				Validators.required,
				Validators.pattern(emailPattern),
			]),
			password: new FormControl("", [Validators.required]),
		});

		this.passwordForm = new FormGroup({
			passwordEmail: new FormControl("", [
				Validators.required,
				Validators.pattern(emailPattern),
			]),
		});
	}

	private padRight(s, n, pad) {
		const t = s;
		if (n > s.length) {
			for (let i = 0; i < n - s.length; i++) {
				s += pad;
			}
		}
		return t;
	}

	login(): void {
		if (this.loginForm.valid) {
			this.bee.loginUser(
				{
					email: this.loginForm.get("email").value,
					password: this.loginForm.get("password").value,
				},
				this.action
			);

			if (!this.loginForm.get("email").value.includes("@agtuary")) {
				// google analytics
			}
		} else {
			this.message = "Please fill in all fields.";

			setTimeout(() => {
				this.message = "";
			}, 3000);
		}
	}

	signup() {}

	changePassword() {
		if (this.passwordForm.valid) {
			const email = this.passwordForm.get("passwordEmail").value;
			console.log(email);
			this.sendingPasswordReset = true;
			this.bee.hive.special
				.startResetPassword({ email }, "1")
				.then((x) => {
					this.sendingPasswordReset = false;
					// @ts-ignore
					if (x.sent) {
						this.sentPasswordReset = true;
					} else {
						this.message =
							"An error occurred. Please contact contact@agtuary.com for help.";

						setTimeout(() => {
							this.message = "";
						}, 10000);
					}
				});
		} else {
			this.message = "Please fill in all fields.";

			setTimeout(() => {
				this.message = "";
			}, 3000);
		}
	}
}
