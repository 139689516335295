<div class="navigation" fxLayout="column">
	<div class="logo">
		<div *ngIf="!app.collapseSidebar; else agtuary">
			<a href="https://www.agtuary.com/" target="_blank">
				<img src="assets/logo-black.svg" class="full-logo" />
			</a>
		</div>
		<ng-template #agtuary>
			<a href="https://www.agtuary.com/" target="_blank">
				<img src="assets/a-agtuary.png" class="small-logo" />
			</a>
		</ng-template>
	</div>
	<div class="side-bar">
		<div class="middle-side" fxLayout="column">
			<div
				class="link"
				*ngFor="let link of app.mainLinks; let i = index"
				[class.active-link]="link.active"
				fxLayout="row"
				fxLayoutAlign="start center"
				(click)="app.navigateMainLinks(link.route, i)"
			>
				<i class="material-icons mainIcon">{{ link.icon }}</i>
				<p class="link-name" [class.display-none]="app.collapseSidebar">
					{{ link.name }}
				</p>
				<i
					*ngIf="
						app.onboardingIcon == 'one' && link.name == 'Portfolio'
					"
					class="material-icons orange onboardingIcon"
					>fiber_manual_record</i
				>
			</div>
		</div>

		<div class="bottom-side" fxLayout="column" fxLayoutAlign="end start">
			<div
				class="window"
				*ngIf="
					!app.collapseSidebar && app.sidebarWindow == 'onboarding'
				"
				fxLayout="column"
				fxLayoutAlign="center center"
			></div>
			<div
				class="link"
				*ngFor="let link of app.bottomLinks; let i = index"
				[class.active-link]="link.active"
				fxLayout="row"
				fxLayoutAlign="start center"
				(click)="app.navigateBottomLink(link.route, i)"
			>
				<i class="material-icons bottomIcon">{{ link.icon }}</i>
				<p class="link-name" [class.display-none]="app.collapseSidebar">
					{{ link.name }}
				</p>
			</div>
			<div class="arrow-container">
				<i
					class="material-icons arrow"
					[class.reverse]="app.collapseSidebar"
					(click)="app.toggleSideMenu()"
					>arrow_back_ios_new</i
				>
			</div>
			<div class="logo" *ngIf="app.isGNC()">
				<div *ngIf="!app.collapseSidebar; else graincorp">
					<a href="https://www.graincorp.com.au/" target="_blank">
						<img src="assets/graincorp.png" class="full-logo" />
					</a>
				</div>
				<ng-template #graincorp>
					<a href="https://www.graincorp.com.au/" target="_blank">
						<img
							src="assets/graincorplogo.png"
							class="small-logo"
						/>
					</a>
				</ng-template>
			</div>
		</div>
	</div>
</div>
