import { Injectable } from '@angular/core';

@Injectable()
export class OnboardingService {
	public signup: any;
	public insights: any;
	public details: any;
	public features: any;
	public join: any;
	public activities: any;

	constructor() {}

	anyNull() {
		return !!this.signup;
	}
}
