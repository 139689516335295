import { featureFlags } from "./features";

type Journey = "portfolio" | "client" | "" | any;

interface IMenuLink {
	name: string;
	route: Journey;
	icon: string;
	active: boolean;
	type: string;
	enabled: boolean;
}

const mainLinks: IMenuLink[] = [
	{
		name: "Map",
		route: "map",
		icon: "place",
		active: false,
		type: "main",
		enabled: featureFlags.menu.map,
	},
];

const bottomLinks: IMenuLink[] = [
	{
		name: "Help",
		route: "help",
		icon: "question_mark",
		active: false,
		type: "bottom",
		enabled: featureFlags.menu.settings,
	},
];

export { IMenuLink, Journey, mainLinks, bottomLinks };
