﻿<div class="background">
	<div
		class="decoration"
		*ngIf="
			this.app.deviceSize == 'tablet' || this.app.deviceSize == 'desktop'
		"
	></div>
</div>

<div class="form-container">
	<div class="form" fxLayout="column" fxLayoutAlign="center center">
		<a
			href="https://www.agtuary.com/"
			target="_blank"
			fxLayoutAlign=" center"
		>
			<img src="assets/logo-black.svg" />
		</a>

		<form
			*ngIf="mode == 'login'"
			[formGroup]="loginForm"
			(ngSubmit)="login()"
			fxLayout="column"
			fxLayoutAlign="center "
		>
			<p class="title-form">{{ title }}</p>
			<div
				class="navigation"
				fxLayout="row"
				fxLayoutAlign="space-around center"
			>
				<p
					class="nav"
					[class.nav-active]="navMode === 'login'"
					fxLayoutAlign="center center"
					(click)="mode = 'login'; navMode = 'login'"
				>
					Log in
				</p>
			</div>

			<div class="input-container">
				<input
					placeholder="Email"
					type="email"
					id="email"
					formControlName="email"
					required
				/>
				<!-- error handling -->
				<div
					*ngIf="email.invalid && (email.dirty || email.touched)"
					class="error"
				>
					<p *ngIf="email.errors.required">Email is required.</p>
					<p *ngIf="email.errors.pattern">Email is not valid.</p>
				</div>
			</div>

			<div class="input-container">
				<input
					placeholder="Password"
					type="password"
					id="password"
					formControlName="password"
					required
				/>
				<!-- error handling -->
				<div *ngIf="password.touched && password.invalid" class="error">
					<p *ngIf="password.errors.required">
						Password is required.
					</p>
				</div>
			</div>

			<div class="error">
				{{ this.app.message?.visible ? this.app.message?.text : "" }}
			</div>

			<div class="error">
				{{ message }}
			</div>

			<app-waiting-icon
				[visible]="this.bee.is.loggingIn || this.app.loading.user"
			></app-waiting-icon>

			<button class="primary-btn" type="submit">Log in</button>
			<p class="link" (click)="mode = 'password'">Forgot password?</p>
		</form>

		<form
			*ngIf="mode == 'signup'"
			[formGroup]="signupForm"
			(ngSubmit)="signup()"
			fxLayout="column"
			fxLayoutAlign="center "
		>
			<p class="title-form">Welcome to Agtuary</p>
			<div
				class="navigation"
				fxLayout="row"
				fxLayoutAlign="space-around center"
			>
				<p
					class="nav"
					[class.nav-active]="navMode === 'login'"
					fxLayoutAlign="center center"
					(click)="mode = 'login'; navMode = 'login'"
				>
					Log in
				</p>
				<p
					class="nav"
					[class.nav-active]="navMode === 'signup'"
					fxLayoutAlign="center center"
					(click)="mode = 'signup'; navMode = 'signup'"
				>
					Sign up
				</p>
			</div>

			<p class="title">Tell us who you are</p>

			<div
				class="role-container"
				fxLayout="row"
				fxLayoutAlign="space-between center"
			>
				<div
					class="role"
					fxLayout="column"
					fxLayoutAlign="center center"
					[class.role-active]="userRoles === 'farmer'"
					(click)="
						userRoles = 'farmer';
						this.signupForm.value.role = 'farmer'
					"
				>
					<img src="assets/pages/farmer.png" />
					<p>Farmer</p>
				</div>
				<div
					class="role"
					fxLayout="column"
					fxLayoutAlign="center center"
					[class.role-active]="userRoles === 'banker'"
					(click)="
						userRoles = 'banker';
						this.signupForm.value.role = 'banker'
					"
				>
					<img src="assets/pages/banker.png" />
					<p>Banker</p>
				</div>
				<div
					class="role"
					fxLayout="column"
					fxLayoutAlign="center center"
					[class.role-active]="userRoles === 'advisor'"
					(click)="
						userRoles = 'advisor';
						this.signupForm.value.role = 'advisor'
					"
				>
					<img src="assets/pages/advisor.png" />
					<p>Advisor</p>
				</div>
			</div>

			<div
				class="role-container"
				fxLayout="row"
				fxLayoutAlign="space-between center"
			>
				<div
					class="role"
					fxLayout="column"
					fxLayoutAlign="center center"
					[class.role-active]="userRoles === 'manager'"
					(click)="
						userRoles = 'manager';
						this.signupForm.value.role = 'manager'
					"
				>
					<img src="assets/pages/manager.png" />
					<p>Asset</p>
					<p>manager</p>
				</div>
				<div
					class="role"
					fxLayout="column"
					fxLayoutAlign="center center"
					[class.role-active]="userRoles === 'valuer'"
					(click)="
						userRoles = 'valuer';
						this.signupForm.value.role = 'valuer'
					"
				>
					<img src="assets/pages/land.png" />
					<p>Land</p>
					<p>valuer</p>
				</div>
				<div
					class="role"
					fxLayout="column"
					fxLayoutAlign="center center"
					[class.role-active]="userRoles === 'other'"
					(click)="
						userRoles = 'other'; this.signupForm.value.role = ''
					"
				>
					<img src="assets/pages/other.png" />
					<p>Other</p>
				</div>
			</div>

			<div *ngIf="userRoles == 'other'" class="input-container">
				<input
					type="text"
					id="other"
					formControlName="other"
					placeholder="Your role"
					(change)="
						this.signupForm.value.role = $event.target.value;
						this.tempRole = $event.target.value
					"
					required
				/>
				<!-- error handling -->
				<div
					*ngIf="other.invalid && (other.dirty || other.touched)"
					class="error"
				>
					<p *ngIf="other.errors.required">Role is required.</p>
				</div>
			</div>

			<div class="input-container">
				<input
					type="text"
					id="firstName"
					formControlName="firstName"
					placeholder="First name"
					required
				/>
				<!-- error handling -->
				<div
					*ngIf="
						firstName.invalid &&
						(firstName.dirty || firstName.touched)
					"
					class="error"
				>
					<p *ngIf="firstName.errors.required">
						First name is required.
					</p>
				</div>
			</div>

			<div class="input-container">
				<input
					type="text"
					id="lastName"
					formControlName="lastName"
					placeholder="Last name"
					required
				/>
				<!-- error handling -->
				<div
					*ngIf="
						lastName.invalid && (lastName.dirty || lastName.touched)
					"
					class="error"
				>
					<p *ngIf="lastName.errors.required">
						Last name is required.
					</p>
				</div>
			</div>

			<div class="input-container">
				<input
					type="text"
					id="company"
					formControlName="company"
					placeholder="Company"
					required
				/>
				<!-- error handling -->
				<div
					*ngIf="
						company.invalid && (company.dirty || company.touched)
					"
					class="error"
				>
					<p *ngIf="company.errors.required">
						Company name is required.
					</p>
				</div>
			</div>

			<div class="input-container">
				<input
					type="email"
					id="email"
					formControlName="emailSignup"
					placeholder="Email"
				/>
				<!-- error handling -->
				<div
					*ngIf="
						emailSignup.invalid &&
						(emailSignup.dirty || emailSignup.touched)
					"
					class="error"
				>
					<p *ngIf="emailSignup.errors.required">
						Email is required.
					</p>
					<p *ngIf="emailSignup.errors.pattern">
						Email is not valid.
					</p>
				</div>
			</div>

			<div class="input-container">
				<input
					type="password"
					id="password"
					formControlName="passwordSignup"
					placeholder="Password"
				/>
				<!-- error handling -->
				<div
					*ngIf="passwordSignup.touched && passwordSignup.invalid"
					class="error"
				>
					<p *ngIf="passwordSignup.errors.required">
						Password is required.
					</p>
					<p *ngIf="passwordSignup.errors?.minlength">
						Password must be min 8 characters.
					</p>
				</div>
			</div>

			<div class="input-container">
				<input
					type="text"
					id="accessCode"
					formControlName="accessCode"
					placeholder="Access code (optional)"
				/>
				<!-- error handling -->
				<!-- <div
					*ngIf="
						accessCode.invalid &&
						(accessCode.dirty || accessCode.touched)
					"
					class="error"
				>
					<p *ngIf="accessCode.errors.required">
						Access code is required.
					</p>
				</div> -->
			</div>

			<div class="input-container check">
				<label for="terms" class="containerCheckbox"
					><span>I have read and agreed to </span>
					<a
						href="https://www.agtuary.com/terms-of-use"
						target="_blank"
						>terms and conditions</a
					>
					<input
						type="checkbox"
						class="checkbox"
						id="terms"
						formControlName="terms"
						required
					/>
					<span class="checkmark"></span>
				</label>
				<div
					*ngIf="terms.invalid && (terms.dirty || terms.touched)"
					class="error"
				>
					<p *ngIf="terms.errors.required">
						You must accept our terms and conditions.
					</p>
				</div>
			</div>

			<div class="error">
				{{ this.app.message?.visible ? this.app.message?.text : "" }}
			</div>

			<div class="error">
				{{ message }}
			</div>

			<app-waiting-icon
				fxLayoutAlign="center center"
				[visible]="
					this.bee.is.loggingIn ||
					this.app.loading.user ||
					this.bee.is.submitting
				"
			></app-waiting-icon>

			<button
				class="primary-btn"
				type="submit"
				*ngIf="
					!(
						this.bee.is.loggingIn ||
						this.app.loading.user ||
						this.bee.is.submitting
					)
				"
			>
				Sign up
			</button>
		</form>

		<form
			class="resetPasswordForm"
			*ngIf="mode == 'password'"
			[formGroup]="passwordForm"
			(ngSubmit)="changePassword()"
			fxLayout="column"
			fxLayoutAlign="center center"
		>
			<p class="title-form">Request to reset password</p>
			<div class="input-container" *ngIf="!sentPasswordReset">
				<input
					type="email"
					id="email"
					formControlName="passwordEmail"
					placeholder="Email"
				/>
				<!-- error handling -->
				<div
					*ngIf="
						passwordEmail.invalid &&
						(passwordEmail.dirty || passwordEmail.touched)
					"
					class="error"
				>
					<p *ngIf="passwordEmail.errors.required">
						Email is required.
					</p>
					<p *ngIf="passwordEmail.errors.pattern">
						Email is not valid.
					</p>
				</div>
			</div>

			<div *ngIf="sentPasswordReset">
				Password reset email sent! Please check your inbox and/or spam
				folder. Email can take up to 5 minutes to deliver.
			</div>

			<div class="error">
				{{ this.app.message?.visible ? this.app.message?.text : "" }}
			</div>

			<div class="error">
				{{ message }}
			</div>

			<app-waiting-icon
				[visible]="
					this.bee.is.loggingIn ||
					this.app.loading.user ||
					sendingPasswordReset
				"
			></app-waiting-icon>

			<button
				class="primary-btn"
				type="submit"
				*ngIf="!sentPasswordReset && !sendingPasswordReset"
			>
				Receive link
			</button>
			<p class="link" (click)="mode = 'login'">Back to log in</p>
		</form>
	</div>
</div>
