import {
	Component,
	AfterViewInit,
	OnInit,
	OnDestroy,
	ComponentFactoryResolver,
} from "@angular/core";
import { BeeService } from "@src/app/core/services/bee/bee.service";
import { AppService } from "@src/app/core/services/app.service";
import { CropMapService } from "@src/app/core/services/crop-map/crop-map.service";
import { EmpireService } from "@src/app/core/services/empire.service";
import * as L from "leaflet";
import "leaflet-draw";

@Component({
	selector: "app-crop-map",
	templateUrl: "./crop-map.component.html",
	styleUrls: ["./crop-map.component.scss"],
})
export class CropMapComponent implements AfterViewInit, OnInit, OnDestroy {
	message = "";
	mapSearch = "";

	constructor(
		public bee: BeeService,
		public app: AppService,
		public map: CropMapService,
		public empire: EmpireService
	) {}

	ngOnInit() {
		this.app.mapControl = "crop";

		this.clearControl();
	}

	ngOnDestroy() {
		this.map.mapDestroy();
	}

	clearControl() {
		this.map.toolPad = "";
		this.map.boundariesToggled = false;
		this.map.cropOpacity = false;
		this.map.imageOpacity = false;
		this.map.highRes = false;
		this.map.sites = false;
	}

	get paddocks() {
		return Object.values(this.bee.state.paddocks).filter(
			(x) => x.user == this.bee.ctx.user.id
		);
	}

	ngAfterViewInit() {
		this.map.generateMap();
		this.map.drawTool();

		setTimeout(() => {
			this.bee.getTotals();
			this.bee.getSites();
			this.bee.getGeojson().then((x) => {
				this.bee.geojson.features.forEach((reg) => {
					// @ts-ignore
					let boundaries = L.geoJSON(reg, {
						style: {
							color: "#22BDA5",
							className: "boundaries",
							pane: "geojsonPane",
							fill: true,
							fillOpacity: 0.001,
							weight: 2,
						},
					});
					let content = `<p>GDM Regions: ${reg.properties.name}`;
					boundaries
						.bindPopup(content, { autoPan: false })
						.on("mouseout", function () {
							this.closePopup();
						})
						.on("mouseover", function () {
							this.openPopup();
						});
					boundaries.addTo(this.map.leaflet);
					this.map.boundaries.push(boundaries);
					this.map.toggleBoundaries();
				});
			});
		}, 1500);
	}

	searchAddress(): void {
		if (this.mapSearch.length > 0) {
			this.map.searchAddress(this.mapSearch);
		}
		this.mapSearch = "";
	}

	zoomIn() {
		this.map.leaflet.setZoom(this.map.leaflet.getZoom() + 1);
	}

	zoomOut() {
		this.map.leaflet.setZoom(this.map.leaflet.getZoom() - 1);
	}

	cropOpacity: number;
	ndviOpacity: number;
	imageOpacity: number;
	crop2Opacity: number;
	harvestOpacity: number;
	floodOpacity: number;

	public opacityChangeCrop2(e) {
		this.map.crop2Layer.setOpacity(this.crop2Opacity / 100);
		this.map.crop2Opacity = true;
	}

	toggleBoundaries() {
		this.map.toggleBoundaries();
	}

	recreateMap() {
		this.map.control = "map";

		setTimeout(() => {
			this.ngAfterViewInit();
		}, 50);
	}

	markers = [];
	pins = [];
	siteLabels = [];
	createLabels: boolean;

	showSites() {
		this.map.sites = !this.map.sites;
		this.createLabels = true;

		let sites = this.bee.sites.stores;

		sites.forEach((site) => {
			let pins = {};
			let coord: number[] = [];

			coord.push(Number(site.latitude));
			coord.push(Number(site.longitude));
			pins["coord"] = coord;
			pins["location"] = site.location;

			let content = `<p><strong>${site.location}</strong><br />Region: ${site.productsServices}</p>`;
			pins["content"] = content;

			this.pins.push(pins);
		});
		this.setPins();
	}

	previousZoom: number = 0;

	setPins() {
		// Update X and Y based on zoom level
		const s2 = 24;

		let icon = L.icon({
			iconUrl: "assets/site-marker.png",
			iconAnchor: [s2 / 2, s2],
			iconSize: [s2, s2],
			popupAnchor: [0, -20],
		});

		this.pins.forEach((pin) => {
			let popup = new L.Popup({
				autoPan: true,
				keepInView: true,
			});

			// @ts-ignore
			let marker = L.marker(pin.coord, {
				icon,
				pane: "pins",
			});

			popup.setContent(pin.content);
			marker.bindPopup(popup);
			marker.addTo(this.map.leaflet);

			this.markers.push(marker);
		});

		this.map.leaflet.on("zoomend", () => {
			let currentZoom = this.map.leaflet.getZoom();

			// case where we go past threshold
			if (
				currentZoom >= 9 &&
				this.previousZoom < 9 &&
				this.createLabels
			) {
				this.pins.forEach((pin) => {
					let markerLabel = L.marker(pin.coord, {
						opacity: 0.01,
					}); // opacity may be set to zero
					markerLabel.bindTooltip(pin.location, {
						permanent: true,
						className: "site-label",
						pane: "site-labels",
					});
					markerLabel.addTo(this.map.leaflet);
					this.siteLabels.push(markerLabel);
				});
			}

			if (
				this.previousZoom >= 9 &&
				currentZoom < 9 &&
				this.createLabels
			) {
				this.siteLabels.forEach((label) => {
					this.map.leaflet.removeLayer(label);
				});
				this.siteLabels.length = 0;
			}

			this.previousZoom = currentZoom;
		});
	}

	hideSites() {
		this.map.sites = !this.map.sites;

		this.markers.forEach((marker) => {
			this.map.leaflet.removeLayer(marker);
		});
		this.siteLabels.forEach((label) => {
			this.map.leaflet.removeLayer(label);
		});
		this.siteLabels.length = 0;
		this.markers.length = 0;
		this.createLabels = false;
	}

	changeCrop2Opacity(str) {
		if (str === "show") {
			this.map.crop2Layer.setOpacity(65 / 100);
			this.map.crop2Opacity = true;
		} else {
			this.map.crop2Layer.setOpacity(0 / 100);
			this.map.crop2Opacity = false;
		}
	}

	changeHighResOpacity(str) {
		if (str === "show") {
			this.map.satellite.setOpacity(100 / 100);
			this.map.highRes = true;
		} else {
			this.map.satellite.setOpacity(0 / 100);
			this.map.highRes = false;
		}
	}

	addPaddock() {
		this.map.toolPad = "add";
		this.map.marker = null;
		this.map.paddockEditMode = false;

		if (this.map.drawnItems.toGeoJSON()["features"].length > 0) {
			this.map.drawnItems.clearLayers();
		}

		// @ts-ignore
		this.map.polygon = new L.Draw.Polygon(this.map.leaflet, {
			shapeOptions: { color: "#ff0000" },
		});
		this.map.polygon.enable();
	}

	displayPaddock() {
		this.map.toolPad = "list";
		this.map.form = "";
		this.map.paddockEditMode = false;
		this.map.setView = true;
	}

	displayRegion() {
		this.map.toolPad = "region";
		this.map.paddockEditMode = false;
	}

	findLocation() {
		this.map.toolPad = "location";
		this.map.paddockEditMode = false;

		this.map.leaflet.locate({
			setView: true,
			maxZoom: 15,
			timeout: 10000,
			maximumAge: 100000,
		});

		let icon = L.icon({
			iconUrl: this.empire.locationMapIcon,
			iconAnchor: [16, 32],
			iconSize: [40, 40],
		});

		navigator.geolocation.getCurrentPosition((position) => {
			if (this.map.currentMarker) {
				this.map.leaflet.removeLayer(this.map.currentMarker);
				this.map.currentMarker = undefined;
			}

			const {
				coords: { latitude, longitude },
			} = position;
			this.map.currentMarker = L.marker([latitude, longitude], {
				icon,
				draggable: false,
				autoPan: true,
			});
			this.map.currentMarker.addTo(this.map.leaflet);
		});

		setTimeout(() => {
			this.map.toolPad = "";
		}, 3500);
	}

	drawLine() {
		this.map.toolPad = "line";

		let latlng = [];
		this.map.markerGroup = L.layerGroup().addTo(this.map.leaflet);

		this.map.leaflet.on("click", (e) => {
			// @ts-ignore
			if (latlng.length >= 2) {
				this.map.leaflet.off("click");
				return;
			}

			let icon = L.icon({
				iconUrl: this.empire.dropPin,
				iconAnchor: [16, 32],
				iconSize: [30, 30],
			});
			// @ts-ignore
			let marker = L.marker(e.latlng, {
				icon,
				draggable: false,
				autoPan: true,
			}).addTo(this.map.markerGroup);

			latlng.push(marker.getLatLng());

			if (latlng.length === 2) {
				this.map.calculateDistance(latlng);
				this.drawPolyline(latlng);
			}
		});
	}

	drawCircle() {
		this.map.toolPad = "circle";

		let latlng = [];
		this.map.circleGroup = L.layerGroup().addTo(this.map.leaflet);

		this.map.leaflet.on("click", (e) => {
			// @ts-ignore
			if (latlng.length >= 2) {
				this.map.leaflet.off("click");
				return;
			}

			let icon = L.icon({
				iconUrl: this.empire.dropPin,
				iconAnchor: [16, 32],
				iconSize: [30, 30],
			});
			// @ts-ignore
			let marker = L.marker(e.latlng, {
				icon,
				draggable: false,
				autoPan: true,
			}).addTo(this.map.circleGroup);

			latlng.push(marker.getLatLng());

			if (latlng.length === 2) {
				this.map.calculateDistance(latlng);
				// @ts-ignore
				L.circle([latlng[0].lat, latlng[0].lng], {
					radius: this.map.distanceMeter,
					color: "#ff0000",
				}).addTo(this.map.circleGroup);

				this.map.calculateCircleArea(this.map.distanceKm);
				this.drawPolyline(latlng);
			}
		});
	}

	drawPolyline(latlng) {
		let polyline = L.polyline(latlng, {
			color: "#ff0000",
		}).addTo(this.map.leaflet);
		this.map.drawnItems.addLayer(polyline);
	}

	addMarker() {
		this.map.toolPad = "pin";
		this.map.form = "crop";
		this.app.mapControl = "crop";
		this.map.paddockEditMode = false;
		this.map.paddockArea = undefined;

		this.map.leaflet.on("click", (e) => {
			if (this.map.marker) {
				this.map.leaflet.off("click");
				return;
			}

			let icon = L.icon({
				iconUrl: this.empire.dropPin,
				iconAnchor: [16, 32],
				iconSize: [30, 30],
			});
			// @ts-ignore
			this.map.marker = L.marker(e.latlng, {
				icon,
				draggable: true,
				autoPan: true,
			});

			this.map.marker
				.on("click", () => {
					this.map.toolPad = "form";
				})
				.addTo(this.map.leaflet);

			this.map.pinCoord = this.map.marker.getLatLng();
			this.map.toolPad = "form";

			this.map.marker.on("move", (e) => {
				// @ts-ignore
				this.map.pinCoord = e.latlng;
			});
		});
	}

	displayForm() {
		this.map.toolPad = "form";
		this.map.form = "crop";
		this.app.mapControl = "crop";
		this.map.paddockEditMode = false;
		this.app.popMessage = "";
	}

	displaySetting() {
		this.map.toolPad = "display";
	}

	close() {
		this.map.toolPad = "";
		this.map.paddockEditMode = false;
		this.app.popMessage = "";

		this.map.clearMap();
	}

	// to update data and a shape for a polygon
	updateShape() {
		this.map.getAreaEditShape();

		if (this.map.drawnItems.toGeoJSON()["features"].length > 0) {
			let paddock = this.bee.ctx.paddock;
			let data = this.bee.ctx.paddock.values;

			let newPaddock = {
				...paddock,
				updated: new Date().toISOString(),
				geojson: JSON.stringify(this.map.drawnItems.toGeoJSON()),
				values: {
					...data,
					area: this.map.paddockArea,
				},
				verified:
					this.bee.ctx.user.id == "us_jCVkzUNbd8QW802mRDtjIJC"
						? true
						: false,
			};

			this.bee.hive.paddocks.update(newPaddock, paddock.id);

			this.app.popMessage = "save";
			this.map.displayInfo = "";

			this.map.drawnItems.clearLayers();
			this.map.visibilityControl(newPaddock);

			setTimeout(() => {
				this.app.popMessage = "";
			}, 1500);
		}
	}
}
