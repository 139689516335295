import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

(window as any).process = {
	env: { DEBUG: undefined },
};

if (environment.production) {
	enableProdMode();
	window.console.debug = () => {};
	console.debug = () => {};
	console.debug = function (a, b, c, d, e, f, g, h, i, j, k, l) {
		return false;
	};
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));
