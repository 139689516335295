import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dev-options',
  templateUrl: './dev-options.component.html',
  styleUrls: ['./dev-options.component.scss']
})
export class DevOptionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
