import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "@src/app/core/services/app.service";
import { BeeService } from "@src/app/core/services/bee/bee.service";

@Component({
	selector: "app-sidebar-navigation",
	templateUrl: "./sidebar-navigation.component.html",
	styleUrls: ["./sidebar-navigation.component.scss"],
})
export class SidebarNavigationComponent implements OnInit, AfterViewInit {
	userLogged: boolean = false;

	constructor(
		private router: Router,
		public bee: BeeService,
		public app: AppService
	) {}

	ngOnInit(): void {
		this.userLogged =
			this.bee.is.loggedIn && !this.router.url.includes("onboarding");
	}


	ngAfterViewInit(): void {}

}
