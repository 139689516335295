import { NgModule } from "@angular/core";

import { MatIconModule } from "@angular/material/icon";
import { MAT_DATE_LOCALE } from "@angular/material/core";

@NgModule({
	imports: [MatIconModule],
	exports: [MatIconModule],
	providers: [{ provide: MAT_DATE_LOCALE, useValue: "en-GB" }],
})
export class MaterialModule {}
