import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { BeeService } from "@src/app/core/services/bee/bee.service";
import { Subscription } from "rxjs";

@Component({
	selector: "app-waiting-icon",
	templateUrl: "./waiting-icon.component.html",
	styleUrls: ["./waiting-icon.component.scss"],
})
export class WaitingIconComponent implements OnInit, OnDestroy {
	@Input() visible = false;

	tick = false;

	constructor(public bee: BeeService) {
		this;
	}

	ngOnInit(): void {}

	ngOnDestroy() {}
}
