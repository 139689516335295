import { Component, OnInit } from "@angular/core";
import { BeeService } from "@src/app/core/services/bee/bee.service";
import { AppService } from "@src/app/core/services/app.service";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
	constructor(public bee: BeeService, public app: AppService) {}

	ngOnInit(): void {}
}
