import { Injectable } from '@angular/core';

/**
 * All sorts of caches.
 */

@Injectable({
	providedIn: 'root',
})
export class CacheService {
	private i = -1;
	private entries = {};
	private dates = {};

	constructor() {}

	public track(s: string) {
		if (!Object.keys(this.entries).includes(s)) {
			const dt = new Date();
			this.entries[s] = dt;
			return true;
		}
		return false;
	}

	// todo: instead of just a string, make it a typed string group and then
	// sub ID string
	public threshold(s: string, minutes: number) {
		const first = this.track(s);

		const dt2 = new Date();
		const dt = this.entries[s];

		const ms = dt2.getTime() - dt.getTime();
		const mins = ms / 1000 / 60;
		const past = mins >= minutes;

		if (past) {
			this.entries[s] = dt2;
		} else {
			console.debug(`${s} cache hit`, this);
		}

		return past || first;
	}

	// TODO: change to luxon
	public date(dt: string): Date {
		if (!Object.keys(this.dates).includes(dt)) {
			this.dates[dt] = new Date(dt);
		}

		return this.dates[dt];
	}
}
