const featureFlags = {
	menu: {
		dashboard: false,
		jobs: false,
		clients: false,
		region: false,
		taskboard: false,
		notifications: false,
		portfolio: false,
		map: false,
		team: false,
		dev: false,
		help: true,
		settings: true,
		logout: true,
	},
	actions: {},
	buttons: {
		exportClientsCSV: false,
	},
};

export { featureFlags };
