import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnInit,
} from "@angular/core";
import { BeeService } from "@src/app/core/services/bee/bee.service";
import { AppService } from "@src/app/core/services/app.service";
import { CacheService } from "@src/app/core/services/cache.service";
import { IPaddock } from "@src/app/core/models/shared/models2";
import { CropMapService } from "@src/app/core/services/crop-map/crop-map.service";

@Component({
	selector: "app-paddock",
	templateUrl: "./paddock.component.html",
	styleUrls: ["./paddock.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaddockComponent implements OnInit {
	listDisplay: "crop" | "pasture" = "crop";

	displayList = true;

	get paddocks() {
		return Object.values(this.bee.state.paddocks)
			.filter((x) => x.user === this.bee.ctx.user.id)
			.sort((a, b) => {
				return (
					this.cache.date(b.created).getTime() -
					this.cache.date(a.created).getTime()
				);
			});
	}

	get cropPaddocksData() {
		console.log("R");
		return this.paddocks.filter((pad) => pad.cropType === "crop");
	}

	get pasturePaddocksData() {
		return this.paddocks.filter((pad) => pad.cropType === "pasture");
	}

	constructor(
		public map: CropMapService,
		public bee: BeeService,
		public app: AppService,
		private cache: CacheService,
		private changes: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		this.map.displayInfo = "";
	}

	cropPaddock = [];
	pasturePaddock = [];

	displayPaddockInfo(pad: IPaddock) {
		this.displayList = false;
		this.cropPaddock.length = 0;
		this.pasturePaddock.length = 0;

		if (pad.cropType === "crop") {
			this.cropPaddock.push(pad);
			this.map.displayInfo = "crop";
		} else {
			this.pasturePaddock.push(pad);
			this.map.displayInfo = "pasture";
		}

		this.changes.detectChanges();
	}

	displayPaddockList() {
		this.displayList = true;
		this.map.displayInfo = "";

		this.cropPaddock.length = 0;
		this.pasturePaddock.length = 0;

		this.changes.detectChanges();
	}

	toggleTab(str) {
		this.displayList = true;
		this.map.displayInfo = "";
		this.map.form = "";

		if (str === "crop") {
			this.listDisplay = "crop";
		} else {
			this.listDisplay = "pasture";
		}
	}

	displayAllCrop() {
		this.map.selectAllCrop = !this.map.selectAllCrop;

		if (this.map.selectAllCrop) {
			this.cropPaddocksData.forEach((pad) => {
				if (this.map.visiblePaddocks[pad.id]) {
					this.map.visiblePaddocks[pad.id] = false;
					this.map.paddockLayers[pad.id].remove();
				}

				this.map.setView = false;
				this.map.visiblePaddocks[pad.id] = true;
				this.map.displayPaddock(pad);
			});
		} else {
			this.cropPaddocksData.forEach((pad) => {
				this.map.visiblePaddocks[pad.id] = false;
				this.map.paddockLayers[pad.id].remove();
			});
			this.map.setView = true;
		}

		this.changes.detectChanges();
	}

	displayAllPasture() {
		this.map.selectAllPasture = !this.map.selectAllPasture;

		if (this.map.selectAllPasture) {
			this.pasturePaddocksData.forEach((pad) => {
				if (this.map.visiblePaddocks[pad.id]) {
					this.map.visiblePaddocks[pad.id] = false;
					this.map.paddockLayers[pad.id].remove();
				}

				this.map.setView = false;
				this.map.visiblePaddocks[pad.id] = true;
				this.map.displayPaddock(pad);
			});
		} else {
			this.pasturePaddocksData.forEach((pad) => {
				this.map.visiblePaddocks[pad.id] = false;
				this.map.paddockLayers[pad.id].remove();
			});
			this.map.setView = true;
		}

		this.changes.detectChanges();
	}
}
