<div class="control" fxLayout="row" fxLayoutAlign="space-between center">
	<p class="greytext">
		Updated: {{ this.bee.totalsLastUpdated | date: "longDate" }}
	</p>
	<p class="message" *ngIf="this.csv">Data copied to clipboard.</p>
	<div class="chartNav" fxLayout="row" fxLayoutAlign="space-around center">
		<div
			class="navIcon"
			fxLayoutAlign=" center"
			[class.active-nav]="this.region == 'table'"
		>
			<app-tooltip
				(click)="toggleChart('table')"
				content="Table"
				icon="toc"
				class="bottom"
				color="this.region == 'table' ? 'green' : 'black'"
			></app-tooltip>
		</div>
		<div
			class="navIcon"
			fxLayoutAlign=" center"
			[class.active-nav]="this.region == 'chart'"
		>
			<app-tooltip
				(click)="toggleChart('chart')"
				content="Bar"
				icon="bar_chart"
				class="bottom"
				color="this.region == 'chart' ? 'green' : 'black'"
			></app-tooltip>
		</div>
		<div class="navIcon" fxLayoutAlign=" center">
			<app-tooltip
				(click)="copyCSV()"
				content="CSV"
				icon="content_cut"
				class="bottom"
				color="black"
			></app-tooltip>
		</div>
	</div>
</div>

<div *ngIf="this.region === 'chart'" id="region"></div>

<div class="table-container" *ngIf="this.region === 'table'">
	<div class="titles" fxLayout="row" fxLayoutAlign="space-between center">
		<p
			[fxFlex]="
				this.app.deviceSize == 'tablet' ||
				this.app.deviceSize == 'desktop'
					? '100px'
					: '75px'
			"
		>
			Region
		</p>
		<p
			fxFlex="50px"
			*ngIf="
				this.app.deviceSize == 'tablet' ||
				this.app.deviceSize == 'desktop'
			"
		>
			State
		</p>
		<p class="number">Sorghum</p>
		<p class="number">Cotton</p>
		<p class="number">Other</p>
		<p
			class="number"
			*ngIf="
				this.app.deviceSize == 'tablet' ||
				this.app.deviceSize == 'desktop'
			"
		>
			Total
		</p>
	</div>

	<div class="table">
		<div class="message">
			<p class="greytext" *ngIf="this.bee.totalsHarvest.length === 0">
				You have no regions listed.
			</p>
		</div>
		<div *ngFor="let reg of this.bee.totalsHarvest" class="items">
			<div
				class="row"
				fxLayout="row"
				fxLayoutAlign="space-between center"
			>
				<p
					class="region-name"
					fxFlex="100px"
					*ngIf="
						this.app.deviceSize == 'tablet' ||
						this.app.deviceSize == 'desktop'
					"
				>
					{{ reg.name | titlecase }}
				</p>
				<p *ngIf="this.app.deviceSize == 'mobile'" class="region-name">
					{{ getLabel(reg.name) | titlecase }}
				</p>

				<p
					fxFlex="50px"
					*ngIf="
						this.app.deviceSize == 'tablet' ||
						this.app.deviceSize == 'desktop'
					"
				>
					{{ reg.total.state }}
				</p>
				<p class="number">
					<span class="harvest">{{
						reg.harvest.sorghum | number: "1.1-1"
					}}</span>
					/<br />
					{{ reg.total.sorghum | number: "1.1-1" }}
				</p>
				<p class="number">
					<span class="harvest">{{
						reg.harvest.cotton | number: "1.1-1"
					}}</span>
					/ <br />
					{{ reg.total.cotton | number: "1.1-1" }}
				</p>
				<p class="number">
					<span class="harvest">{{
						reg.harvest.other | number: "1.1-1"
					}}</span>
					/<br />
					{{ reg.total.other | number: "1.1-1" }}
				</p>
				<p
					class="number"
					*ngIf="
						this.app.deviceSize == 'tablet' ||
						this.app.deviceSize == 'desktop'
					"
				>
					<span class="harvest">{{
						reg.harvest.total | number: "1.1-1"
					}}</span>
					/<br />
					{{ reg.total.total | number: "1.1-1" }}
				</p>
			</div>
		</div>
	</div>
</div>
