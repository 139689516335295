import { Component } from "@angular/core";
import { BeeService } from "./core/services/bee/bee.service";
import { environment } from "@src/environments/environment";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent {
	title = "newyork";

	private i: boolean = false;
	public environment = environment;

	constructor(private bee: BeeService) {
		this.bee.checkLogin();

		if (
			this.i &&
			!environment.production &&
			!window.location.href.includes("portal")
		) {
			this.bee
				.loginUser({
					email: environment.autologinEmail,
					password: environment.autologinPassword,
				})
				.then((x) => {});
		}
	}
}
