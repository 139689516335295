import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { HttpClientModule } from "@angular/common/http";
// import { NgxChartsModule } from "@swimlane/ngx-charts";

import { MaterialModule } from "./shared/material.module";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ContainerComponent } from "@components/container/container.component";

import { LoginComponent } from "@pages/login/login.component";

// Customer
import { HeaderComponent } from "@components/header/header.component";
// Onboarding
import { OnboardingService } from "./core/services/onboarding.service";

//
import { PopupParentComponent } from "@components/popup-parent/popup-parent.component";
import { PopupDirective } from "./core/directives/popup.directive";
import { PopupService } from "./core/services/popup.service";
import { SidebarNavigationComponent } from "@components/sidebar-navigation/sidebar-navigation.component";
import { DevOptionsComponent } from "./core/dev/empty/components/dev-options/dev-options.component";
import { DevOptionsService } from "./core/dev/empty/services/dev-options.service";
import { ConfirmPopupComponent } from "./modules/components/confirm-popup/confirm-popup.component";
import { AppService } from "./core/services/app.service";
import { CommonModule } from "@angular/common";
import { CropMapComponent } from "./modules/pages/crop-map/crop-map.component";
import { SharedModule } from "./shared/shared.module";
import { environment } from "../environments/environment";

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		ContainerComponent,
		HeaderComponent,
		PopupParentComponent,
		PopupDirective,
		SidebarNavigationComponent,
		DevOptionsComponent,
		ConfirmPopupComponent,
		CropMapComponent,
	],
	imports: [
		BrowserModule,
		CommonModule,
		HttpClientModule,
		BrowserAnimationsModule,
		FlexLayoutModule,
		MaterialModule,
		FormsModule,
		ReactiveFormsModule,
		AppRoutingModule,
		SharedModule,
	],
	providers: [AppService, OnboardingService, PopupService, DevOptionsService],
	bootstrap: [AppComponent],
})
export class AppModule {}
