import {
	Component,
	ComponentFactoryResolver,
	OnInit,
	Type,
	ViewChild,
	ViewContainerRef,
} from "@angular/core";
import { PopupDirective } from "@src/app/core/directives/popup.directive";
import { PopupService } from "@src/app/core/services/popup.service";

@Component({
	selector: "app-popup-parent",
	templateUrl: "./popup-parent.component.html",
	styleUrls: ["./popup-parent.component.scss"],
})
export class PopupParentComponent implements OnInit {
	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		public popup: PopupService
	) {}
	@ViewChild(PopupDirective, { static: true }) adHost: PopupDirective;
	@ViewChild("container", { read: ViewContainerRef, static: true })
	container: ViewContainerRef;

	ngOnInit(): void {}

	loadComponent<T>(component: Type<T>, data: any) {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
			component
		);

		// const viewContainerRef = this.adHost.viewContainerRef;
		// viewContainerRef.clear();

		// const componentRef = viewContainerRef.createComponent(componentFactory);
		// componentRef.instance.data = data;
	}
}
