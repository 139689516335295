import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";
import { AppService } from "@src/app/core/services/app.service";
import { BeeService } from "@src/app/core/services/bee/bee.service";
import { CropMapService } from "@src/app/core/services/crop-map/crop-map.service";

@Component({
	selector: "app-popup-message",
	templateUrl: "./popup-message.component.html",
	styleUrls: ["./popup-message.component.scss"],
})
export class PopupMessageComponent implements OnInit {
	@Input() newFeature;
	@Input() base: string = "";

	@Output() displayForm = new EventEmitter();
	@Output() updateShape = new EventEmitter();

	constructor(
		public app: AppService,
		public map: CropMapService,
		public bee: BeeService
	) {}

	ngOnInit(): void {}

	form() {
		this.displayForm.emit();
	}

	update() {
		this.updateShape.emit();
	}

	removePin() {
		if (this.map.paddockLayers[this.bee.ctx.paddock.id]) {
			this.map.paddockLayers[this.bee.ctx.paddock.id].remove(
				this.map.leaflet
			);
		}
	}

	clear() {
		this.app.popMessage = "";
		this.map.dynamicTool = "";
		this.map.toolPad = "";
		this.map.paddockEditMode = false;
		this.map.paddockAddPin = false;

		if (this.map.drawnItems.toGeoJSON()["features"].length > 0) {
			this.map.drawnItems.clearLayers();
			// this.removePin();
		} else {
			// this.removePin();
		}
		this.map.clearMap();
	}

	drawShape() {
		this.map.toolPad = "add";
		this.map.marker = null;
		this.app.popMessage = "";

		//@ts-ignore
		this.map.polygon = new L.Draw.Polygon(this.map.leaflet, {
			shapeOptions: { color: "#f00" },
		});
		this.map.polygon.enable();
	}

	// to update data and a shape for a pin
	addShapePin() {
		let paddock = this.bee.ctx.paddock;
		let data = this.bee.ctx.paddock.values;

		let newPaddock = {
			...paddock,
			created: new Date().toISOString(),
			geojson: JSON.stringify(this.map.drawnItems.toGeoJSON()),
			values: {
				...data,
				area: this.map.paddockArea,
			},
		};

		this.bee.hive.paddocks.update(newPaddock, paddock.id);

		this.app.popMessage = "save";
		this.map.form = "";
		this.map.toolPad = "";
		this.map.paddockAddPin = false;

		this.removePin();
		this.map.drawnItems.clearLayers();
		this.map.visibilityControl(newPaddock);

		setTimeout(() => {
			this.app.popMessage = "";
		}, 1500);
	}
}
