<div class="notification" *ngIf="this.app.popMessage === 'form'">
	<p class="title">Draw shape</p>
	<p>
		Area <span>{{ this.map.paddockArea }}</span> ha
	</p>
	<div class="btn" fxLayout="row" fxLayoutAlign="space-between center">
		<button class="red-btn" (click)="clear()">Cancel</button>
		<button class="green-btn" (click)="form()">Continue</button>
	</div>
</div>

<div class="notification" *ngIf="this.app.popMessage === 'connect'">
	<div fxLayout="row" fxLayoutAlign="space-around center">
		<i class="material-icons red">priority_high</i>
		<p>There was an issue with internet connection.</p>
	</div>
	<div fxLayout="row" fxLayoutAlign="space-around center">
		<i class="material-icons green">done</i>
		<p>The paddock information is successfully saved.</p>
	</div>
</div>

<div class="notification" *ngIf="this.app.popMessage === 'save'">
	<div fxLayout="row" fxLayoutAlign="space-around center">
		<i class="material-icons green">done</i>
		<p>The paddock information is successfully saved.</p>
	</div>
</div>

<div class="notification" *ngIf="this.app.popMessage === 'update'">
	<p class="title">Update shape</p>
	<div class="btn" fxLayout="row" fxLayoutAlign="space-between center">
		<button class="red-btn" (click)="clear()">Cancel</button>
		<button class="green-btn" (click)="update()">Update</button>
	</div>
</div>

<div class="notification" *ngIf="this.app.popMessage === 'pin'">
	<p class="title">Add shape</p>
	<div class="btn" fxLayout="row" fxLayoutAlign="space-between center">
		<button class="red-btn" (click)="clear()">Cancel</button>
		<button class="green-btn" (click)="drawShape()">Draw</button>
	</div>
</div>

<div class="notification" *ngIf="this.app.popMessage === 'confirm'">
	<p class="title">Update shape</p>
	<p>
		Area <span>{{ this.map.paddockArea }}</span> ha
	</p>
	<div class="btn" fxLayout="row" fxLayoutAlign="space-between center">
		<button class="red-btn" (click)="clear()">Cancel</button>
		<button class="green-btn" (click)="addShapePin()">Update</button>
	</div>
</div>

<div class="notification" *ngIf="this.app.popMessage === 'delete'">
	<div fxLayout="row" fxLayoutAlign="space-around center">
		<i class="material-icons red">priority_high</i>
		<p>The paddock information is deleted.</p>
	</div>
</div>

<div class="notification" *ngIf="this.app.popMessage === 'distance'">
	<p *ngIf="this.map.toolPad === 'line'">
		Distance <span>{{ this.map.distanceKm }}</span> km
	</p>

	<div *ngIf="this.map.toolPad === 'circle'">
		<p>
			Radius <span>{{ this.map.distanceKm }}</span> km
		</p>
		<p>
			Area <span>{{ this.map.circleArea }}</span> km2
		</p>
	</div>
	<div class="btn" fxLayout="row" fxLayoutAlign="space-between center">
		<button class="red-btn" (click)="clear()">Cancel</button>
	</div>
</div>
