import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";

import { map } from "rxjs/operators";

import {
	IActionInvite,
	IActionVerify,
	Access,
	IPayloadInvite,
	IPortalResponse,
	ISocketMessage,
	ISocketUpdate,
} from "@core/models/shared/types";

import { IError, IResponse, IPostOptions } from "../../models/internal.types";
import { environment } from "@src/environments/environment";
import { AppService } from "../app.service";
import {
	Updatable,
	CreatableOpt,
	Creatable,
} from "@src/app/core/models/shared/meta";
import {
	collections as C,
	UUIDkeys,
	_collections as CT,
	_collections,
} from "@core/models/shared/fauna.template";
import {
	IBlob,
	IMessage,
	INotification,
	IOrganisation,
	IPortal,
	IProcess,
	IProject,
	ITeam,
	IUser,
	ITask,
	IRole,
	IConfig,
	IRegion,
	IAnalytics,
	IDomain,
} from "../../models/shared/models2";

import { IBlobForm, IBlobFile } from "../../models/shared/types";
import { CacheService } from "../cache.service";

// Information required for logging in an account
interface ILogin {
	email: string;
	password: string;
}

interface Iid {
	id: string;
}

type IBlobAny = IBlob<IBlobForm | IBlobFile>;

interface IState {
	//
	organisations: { [id: string]: IOrganisation };
	teams: { [id: string]: ITeam };
	domains: { [id: string]: IDomain };
	//
	users: { [id: string]: IUser };
	roles: { [id: string]: IRole };
	configs: { [id: string]: IConfig };
	//
	projects: { [id: string]: IProject };
	tasks: { [id: string]: ITask };
	processes: { [id: string]: IProcess };
	//
	messages: { [id: string]: IMessage };
	notifications: { [id: string]: INotification };
	portals: { [id: string]: IPortal };
	blobs: { [id: string]: IBlobAny };

	// TODO: rename
	paddocks: { [id: string]: IRegion };
	analytics: { [id: string]: IAnalytics<any> };
	regions: { [id: string]: IRegion };
}

interface IContext {
	//
	organisation?: IOrganisation;
	team?: ITeam;
	domain?: IDomain;
	//
	user?: IUser;
	role?: IRole;
	config?: IConfig;
	//
	project?: IProject;
	task?: ITask;
	tasks?: ITask[];
	process?: IProcess;
	processes?: IProcess[];

	//
	message?: IMessage;
	notification?: INotification;
	portal?: IPortal;
	blob?: IBlobAny;
	blobs?: IBlobAny[];

	paddock?: IRegion;
	analytics?: IAnalytics<any>;
	region?: IRegion;

	// special
	taskFormFiles?: { [name: string]: File };
}

const cache: any = {
	DEFAULT:
		"https://agtuary-media-public.s3-ap-southeast-2.amazonaws.com/Group+804+2.png",
};

@Injectable({
	providedIn: "root",
})
export class BeeService {
	constructor(
		private http: HttpClient,
		private router: Router,
		private app: AppService,
		private cache: CacheService
	) {
		this.url = environment.bee;
	}

	public get isPremiumTeam(): boolean {
		const team = this.ctx.team.id;
		const ds = Object.values(this.state.domains).filter(
			(x) => x.team == team
		);

		if (ds && ds.length >= 1) {
			return ds[0].plan == "premium";
		}

		return false;
	}
	// State
	public authenticated = false;

	public state: IState = {
		organisations: {},
		teams: {},
		domains: {},
		//
		users: {},
		roles: {},
		configs: {},
		//
		projects: {},
		tasks: {},
		processes: {},
		//
		messages: {},
		notifications: {},
		portals: {},
		blobs: {},
		paddocks: {},
		analytics: {},
		regions: {},
	};

	public ctx: IContext = {
		config: { id: "", colors: [], colorsDirection: "" },
		taskFormFiles: {},
	};

	public is = {
		error: "", // current error message to be displaye
		loggingIn: false,
		loggedIn: false,
		authenticated: false, // same as `loggedIn`
		submitting: false, // if we are submitting data to the API
		autoSaving: false, // currently auto saving
	};

	private customerLSKey = "agtuaryCustomer"; // key for customer object in local storage
	private lastSubmit = 0;
	public landingRoute = ["1", "map"];

	public url: string;

	private headers: HttpHeaders;
	private headersMultipart: HttpHeaders;
	private options: IPostOptions;
	private optionsTiles: IPostOptions;
	private optionsCredentials: IPostOptions;
	private key: string;

	public metadata: any;

	public productCatalogue: any[] = [];

	timer = setInterval(() => this.checkConnection(), 20000);

	// TODO: this has to be named, so we just pass in the collection name, and in
	// bee service it automatically saves it to the state, updates current etc
	public hive = {
		//
		organistions: this._cud<IOrganisation>(C.ORGANISATION),
		teams: this._cud<ITeam>(C.TEAM),
		domains: this._cud<ITeam>(C.DOMAIN),
		//
		user: this._cud<IUser>(C.USER),
		roles: this._cud<IRole>(C.ROLE),
		configs: this._cud<IConfig>(C.CONFIG),
		//
		projects: this._cud<IProject>(C.PROJECT),
		tasks: this._cud<ITask>(C.TASK),
		processes: this._cud<IProcess>(C.PROCESS),
		//
		messages: this._cud<IMessage>(C.MESSAGE, "", true),
		notifications: this._cud<INotification>(C.NOTIFICATION, "", true),
		portals: this._cud<IPortal>(C.PORTAL),
		blobs: this._cud<IBlobAny>(C.BLOB),
		paddocks: this._cud<IRegion>(C.PADDOCK),
		analytics: this._cud<IAnalytics<any>>(C.ANALYTICS),
		regions: this._cud<IRegion>(C.REGION),

		get: {
			fileSingle: this._getUnique<string>(C.BLOB, "file"),
			analyticsFileSingle: this._getUnique<string>(C.ANALYTICS, "file"),
			actionInvite: this._get<IActionInvite>(C.ACTION),
			actionVerify: this._get<IActionVerify>(C.ACTION),
		},
		update: {
			// actionInvite: this._update<IPayloadInvite>(C.ACTION, "invite"),
			// forms: this._update<IFormSubmission>(`/v1/forms/test`),
		},
		create: {},
		special: {
			logout: this._update<{ id: string }>(C.USER, "logout"),
			processTemplates: this._get<IProcess>(C.PROCESS, "templates"),
			taskTemplates: this._get<ITask>(C.TASK, "templates"),
			portalPublic: this._get<IPortalResponse>(C.PORTAL, "public"),
			userAvatar: this._update<any, any>(C.USER, "avatar"),
			checkout: this._update<any, any>(C.TEAM, "create-checkout-session"),
			createTeam: this._update<ITeam>(C.TEAM, "create-from"),
			actionVerify: this._update<IActionVerify>(C.ACTION, "verify"),
			analyseRegion: this._update<any>(C.REGION, "analyse"),
			startResetPassword: this._update<any>(
				C.ACTION,
				"startresetpassword"
			),
			startInvite: this._update<any>(C.ACTION, "startinvite"),
			endInvite: this._update<any>(C.ACTION, "endinvite"),
			endResetPassword: this._update<any>(C.ACTION, "endresetpassword"),
			// @ts-ignore
			buyPropertyReport: this._update<any>("payments", "property-report"),

			// @ts-ignore
			subscribe: this._update<any>("payments", "subscribe"),
			reportProductCatalogue: this._get<any>(
				// @ts-ignore
				"payments",
				"product-catalogue"
			),
			previewReportPrice: this._update<any>(
				// @ts-ignore
				"geo",
				"price-preview"
			),
			// @ts-ignore
			cancelSubscription: this._update<any>("payments", "cancel"),
			analyticsFileSingle: this._update<any>(C.ANALYTICS, "file"),
		},
	};

	private uuid = "";
	private bee: string;

	totals: {
		name: string;
		state: string;
		total: number;
		sorghum: number;
		cotton: number;
		other: number;
	}[];
	harvest: {
		name: string;
		state: string;
		total: number;
		sorghum: number;
		cotton: number;
		other: number;
	}[];
	totalsLastUpdated: string;
	geojson: any;
	sites: any;
	totalsHarvest: any[] = [];

	checkoutUrl = "";
	qrCodeUrl = "";

	private parseResponse = <T>(r: IResponse<T[]>): T[] => {
		if (Object.keys(r.error).length > 0) {
			throw r.error;
		}
		return r.data;
	};

	//#region HTTP method factories

	private buildURL(
		endpoint: string,
		suffix?: string,
		id?: string,
		version: string = "v1"
	) {
		const s = suffix && suffix.length > 1 ? "/" + suffix : "";
		// No need for extra case just for suffix, because suffix is only used
		// when there is an id, for now
		const url = `${this.url}${endpoint}${
			id && id.length >= 1 ? "/" + id : ""
		}${s}`;
		return url;
	}

	private resolveEndpoint(collection: string, version: string = "v1") {
		return `/${version}/${collection}`;
	}

	// TODO: Fix all of the urls in these _GET, etc.

	/**
	 * Factory for performing a GET request.
	 * @param endpoint
	 */
	private _get<T extends Iid>(collection: _collections, suffix: string = "") {
		return (payload?: Iid) => {
			console.log(collection);
			const e = this.resolveEndpoint(collection);

			if (collection == "messages") {
				this.app.loading.messages = true;
			}
			if (collection == "blobs") {
				this.app.loading.blobs = true;
			}

			const url = this.buildURL(e, suffix, payload?.id);
			console.debug(
				`GET ${collection} / ${suffix} (${url})`,
				this,
				payload
			);

			return this.http
				.get<IResponse<T[]>>(url, this.options)
				.pipe(map(this.parseResponse))
				.toPromise()
				.then((x) => {
					if (collection == "messages") {
						this.app.loading.messages = false;
					}
					if (collection == "blobs") {
						this.app.loading.blobs = false;
					}

					this.stateUpdateCollection<T>(collection, x);
					return x;
				});
		};
	}

	private _getUnique<T>(collection: string, suffix: string = "") {
		return (payload: Iid) => {
			const e = this.resolveEndpoint(collection);

			const url = this.buildURL(e, suffix, payload.id);

			return this.http
				.get<IResponse<T[]>>(url, this.options)
				.pipe(map(this.parseResponse))
				.toPromise()
				.then((x) => {
					return x;
				});
		};
	}

	/**
	 * Factory for performing a POST request for creating.
	 * @param endpoint
	 */
	private _create<T extends Iid>(
		collection: _collections,
		defaults: CreatableOpt<T> = {},
		addInstantly: boolean = false
	) {
		return (payload: CreatableOpt<T>) => {
			const rId = addInstantly
				? `${Math.floor(Math.random() * 1000000)}`
				: undefined;
			if (addInstantly) {
				// this will instantly add the document to the state, so we can
				// instantly update the UI but once the API has been called
				// it will swap in the actual response document
				this.stateUpdateCollection<T>(collection, [payload as T], rId);
			}

			if (collection == "portals") {
				this.app.submitting.portal = true;
			}
			if (collection == "tasks") {
				this.app.submitting.task = true;
			}
			if (collection == "projects") {
				this.app.submitting.clients = true;
			}

			const e = this.resolveEndpoint(collection);
			const url = this.buildURL(e);

			console.debug(`CREATE ${collection} (${url})`, this, payload);

			const d: CreatableOpt<T> = {
				...payload,
				...defaults,
			};

			return this.http
				.post<IResponse<T[]>>(url, d, this.options)
				.pipe(map(this.parseResponse))
				.toPromise()
				.then((x) => {
					if (collection == "portals") {
						this.app.submitting.portal = false;
					}
					if (collection == "tasks") {
						this.app.submitting.task = false;
					}
					if (collection == "projects") {
						this.app.submitting.clients = false;
					}
					this.stateUpdateCollection<T>(collection, x, rId);
					return x;
				})
				.catch((x) => {
					this.app.popMessage = "connect";

					if (collection == "paddocks") {
						this.savePaddockLocalStorage(payload);
					}

					setTimeout(() => {
						this.app.popMessage = "";
					}, 6000);

					return undefined;
				});
		};
	}

	private savePaddockLocalStorage(payload) {
		let data = [];
		data = JSON.parse(localStorage.getItem("paddockData")) || [];
		data.push(payload);
		localStorage.setItem("paddockData", JSON.stringify(data));
	}

	private checkConnection() {
		const url = "https://api.agtuary.app/v1/meta/marco";
		this.http
			.get(url)
			.toPromise()
			.then((x) => {
				const data = JSON.parse(localStorage.getItem("paddockData"));

				if (!data) {
					return;
				}

				data.forEach((pad, index) => {
					this.hive.paddocks.create(pad).then((x) => {
						if (x !== undefined) {
							this.app.popMessage = "save";

							data.splice(index, 1);
							localStorage.setItem(
								"paddockData",
								JSON.stringify(data)
							);

							setTimeout(() => {
								this.app.popMessage = "";
							}, 6000);
						}
					});
				});
			});
	}

	/**
	 * Factory for performing a POST request for updating.
	 * @param endpoint
	 */
	// TODO: add optional parameter to update in ctx
	private _update<T extends Iid, K extends Iid = T>(
		collection: _collections,
		suffix: string = "",
		addInstantly: boolean = false
	) {
		return (payload: Updatable<T>, id?: string, updateCtx: string = "") => {
			// TODO: this is crap but who cares, will change types in appservice
			if (collection == "projects") {
				this.app.submitting.clients = true;
			}
			if (collection == "users") {
				this.app.submitting.settings = true;
			}
			if (collection == "configs") {
				this.app.submitting.settings = true;
			}

			if (addInstantly) {
				// this will instantly add the document to the state, so we can
				// instantly update the UI but once the API has been called
				// it will swap in the actual response document
				// TODO: save the previous version, in case of error just
				// replace the previous version
				this.stateUpdateCollection<T>(collection, [payload as T], id);
			}

			const e = this.resolveEndpoint(collection);
			const url = this.buildURL(e, suffix, id);

			console.debug(
				`UPDATE ${collection} / ${suffix} (${url}) - ${id}`,
				this,
				payload
			);

			return this.http
				.post<IResponse<K[]>>(url, payload, this.options)
				.pipe(map(this.parseResponse))
				.toPromise()
				.then((x) => {
					this.stateUpdateCollection<K>(collection, x);

					// TODO: this is crap but who cares, will change types in appservice
					if (collection == "projects") {
						this.app.submitting.clients = false;
					}
					if (collection == "users") {
						this.app.submitting.settings = false;
					}
					if (collection == "configs") {
						this.app.submitting.settings = false;
					}

					if (
						updateCtx &&
						updateCtx.length != 0 &&
						Object.keys(this.ctx).includes(updateCtx) &&
						x &&
						x.length == 1
					) {
						this.ctx[updateCtx] = x[0];
					}

					return x;
				});
		};
	}

	/**
	 * Factory for performing a POST request for deleting.
	 * @param endpoint
	 */
	private _delete<T extends Iid>(
		collection: _collections,
		suffix: string = "delete"
	) {
		return (id: string) => {
			const e = this.resolveEndpoint(collection);

			const url = this.buildURL(e, suffix, id);

			console.debug(
				`DELETE ${collection} / ${suffix} (${url}) - ${id}`,
				this
			);

			return this.http
				.post<IResponse<T[]>>(url, {}, this.options)
				.pipe(map(this.parseResponse))
				.toPromise()
				.then((x) => {
					this.stateUpdateCollection<T>(
						collection,
						x,
						undefined,
						true
					);
					return x;
				});
		};
	}

	private _cud<T extends Iid>(
		collection: _collections,
		suffix: string = "",
		instantly = false
	) {
		return {
			get: this._get<T>(collection),
			create: this._create<T>(collection, undefined, instantly),
			update: this._update<T>(collection, suffix, instantly),
			delete: this._delete<T>(collection, "delete"),
		};
	}

	//#endregion

	public setKey(key: string, id?: string) {
		this.key = key;
		console.debug("Updating key", this, { key });
		this.headers = new HttpHeaders()
			.set("Authorization", `Basic ${this.key}`)
			// .set("User-Agent", window.navigator.userAgent)
			.set(
				"X-Agtuary-Client-User-Agent",
				`Agtuary/New York/${environment.version}`
			)
			.set("Agtuary-Account", id || "default");

		this.headersMultipart = new HttpHeaders()
			.set("Authorization", `Basic ${this.key}`)
			// .set("User-Agent", window.navigator.userAgent)
			.set(
				"X-Agtuary-Client-User-Agent",
				`Agtuary/New York/${environment.version}`
			)
			.set("Agtuary-Account", id || "default")
			.set("Content-Type", "multipart/form-data");

		this.options = {
			headers: this.headers,
			responseType: "json",
			// withCredentials: true,
		};

		this.optionsTiles = {
			headers: this.headers,
			// @ts-ignore
			responseType: "blob",
			// withCredentials: true,
		};

		this.optionsCredentials = {
			headers: this.headers,
			responseType: "json",
			withCredentials: true,
		};
	}

	private async check<T>(p: IResponse<T>) {
		if (
			Object.keys(p).includes("error") &&
			Object.keys(p.error).length > 0
		) {
			throw p.error;
		}

		return p.data;
	}

	public loginUserCall(payload: ILogin): Promise<IUser> {
		return this.http
			.post<IResponse<IUser>>(
				`${this.url}/v1/users/login`,
				payload,
				this.options
			)
			.toPromise()
			.then((x) => this.check(x))
			.then((x) => {
				if (x.secret) {
					this.setKey(x.secret);
					const { secret: removed, ...user } = x;
				}
				return x;
			});
	}

	readonly logout = () => {
		this.http.post<IResponse<boolean>>(
			`${this.url}/v1/users/${this.ctx.user.id}/logout`,
			{},
			this.options
		);

		this.key = "";

		for (const k of Object.keys(this.state)) {
			this.state[k] = {};
		}

		this.ctx = {};

		window.location.href = "https://agtuary.app/login";
	};

	private handleError(error: IError, caller?: string) {
		this.app.showMessage({ text: error.description, type: "error" });
		this.is.submitting = false;
		this.is.loggingIn = false;

		console.debug(error.code + " " + error.description, this, "error");
	}

	// TODO: use another service for local storage/caching (maybe?)
	public getLocalJson<T>(key: string): {} | T {
		const d = localStorage.getItem(key);

		if (!d || d == "") {
			return {};
		}

		return JSON.parse(atob(d)) as T;
	}

	public setLocalJson<T>(key: string, value: T) {
		localStorage.setItem(key, btoa(JSON.stringify(value)));
	}

	//#region Modify state

	public setDevOptions(dev: any) {
		this.setLocalJson("dev", dev);

		if (dev.url && dev.url.length > 0) {
			environment.bee = dev.url || environment.bee;
			this.url = environment.bee;
		}
	}

	// TODO: better type this
	private stateUpdateCollection<T extends Iid>(
		c: _collections,
		a: T[],
		rId?: string,
		remove: boolean = false
	) {
		if (!a || a.length == 0 || Object.keys(a).length == 0) {
			return;
		}
		if (!Object.keys(this.state).includes(c)) {
			return a;
		}

		if (c == "users" || c == "roles") {
		}

		if (a && a.length == 1 && (!a[0].id || a[0].id.length == 0)) {
			console.debug(`Instantly adding ${c} in state`, this, a);
			this.state[c][rId || ""] = { ...this.state[c][rId || ""], ...a[0] };
		} else {
			console.debug(`Updating ${c} in state`, this, a);

			if (remove) {
				delete this.state[c][a[0].id];
			} else {
				for (const v of a) {
					this.state[c][v.id] = v;
				}
				delete this.state[c][rId || ""];
			}
		}

		return a;
	}

	private stateUpdateUser(user: IUser, setLocalStorage = true) {
		this.state.users = { [user.id]: user };
		this.ctx.user = user;

		this.authenticated = true;
		this.is.loggingIn = false;
		// this.app.loading.user = false;
		this.is.loggedIn = true;

		if (setLocalStorage) {
			this.setLocalJson(this.customerLSKey, this.ctx.user);
		}
	}

	//#endregion

	private _cpost<T>(
		url: string,
		data: any,
		options?: IPostOptions
	): Promise<T[]> {
		if (!options) {
			options = this.options;
		}

		return this.http
			.post<IResponse<T>>(url, data, options)
			.toPromise()
			.then((x) => this.check(x)) as Promise<T[]>;
	}

	private _cget<T>(url: string, options?: IPostOptions): Promise<T[]> {
		if (!options) {
			options = this.options;
		}

		return this.http
			.get<IResponse<T>>(url, options)
			.toPromise()
			.then((x) => this.check(x)) as Promise<T[]>;
	}

	public async loginUser(
		payload: ILogin,
		action: string = "",
		getData = true
	) {
		this.is.loggingIn = true;

		console.debug("Logging in user", this, payload);

		this.app.loading.user = true;

		let devLoginFromLocalStorage = false;

		const invited = action.includes("ac_");

		if (environment.local) {
			const loginInfo = this.getLocalJson("loginInfo") as any;

			if (Object.keys(loginInfo).length > 0) {
				if (
					loginInfo.lastLogin &&
					new Date().getTime() -
						new Date(loginInfo.lastLogin).getTime() <
						1000 * 60 * 30
				) {
					const { lastLogin: removed, ...li } = loginInfo;
					this.setKey(li.secret);
					this.stateUpdateUser(li);
					devLoginFromLocalStorage = true;
				}
			}
		}

		if (!devLoginFromLocalStorage) {
			const success = await this.loginUserCall(payload)
				.then((au) => {
					const { secret: removed, ...user } = au;
					this.stateUpdateUser(user);

					if (environment.local) {
						this.setLocalJson("loginInfo", {
							...au,
							lastLogin: new Date().toISOString(),
						});
					}
					return true;
				})
				.catch((e) => {
					this.handleError(e, "Login customer");
					return false;
				});
			if (!success) {
				this.app.loading.user = false;
				this.is.loggingIn = false;
				return;
			}

			if (invited) {
				console.log("invited");
				await this.hive.special.endInvite({}, action);
			}
		}

		if (!getData) {
			return;
		}

		// this.router.navigate(this.landingRoute);

		// this.getRolesAndGroups();

		// this.refreshMapToken();

		this.bootstrapOrganisations().then((x) => {
			this.refreshMapToken();

			// this.router.navigate(this.landingRoute);

			setInterval(() => {
				this.refreshMapToken();
			}, 5 * 60 * 1000); // 60 * 30 * 1000

			this.bootstrapDataTeam().then((x) => {
				this.router.navigate(this.landingRoute);
				this.app.loading.user = false;
			});
		});
	}

	private testCache<T>(
		s: string,
		p: (payload?: Iid) => Promise<T[]>,
		payload?: Iid
	): Promise<T[] | undefined> {
		if (this.cache.threshold(s, 5)) {
			return p(payload);
		} else {
			return new Promise((resolve, reject) => {
				resolve(undefined);
			});
		}
	}

	public bootstrapOrganisations() {
		console.debug("Bootstrapping organisations", this);
		return Promise.all([
			this.testCache("GetOrganisations", this.hive.organistions.get),
			this.testCache("GetTeams", this.hive.teams.get),
			// this.testCache("GetConfigs", this.hive.configs.get),
			// this.testCache("GetNotifications", this.hive.notifications.get),
			// this.testCache("GetRoles", this.hive.roles.get),
			// this.testCache("GetDomains", this.hive.domains.get),
		]).then((x) => {
			// this.ctx.organisation = Object.values(this.state.organisations)[0];

			// if (
			// 	this.ctx.organisation?.id &&
			// 	this.ctx.organisation.id.length > 0
			// ) {

			// }

			if (Object.keys(this.state.organisations).length >= 1) {
				this.ctx.organisation = Object.values(
					this.state.organisations
				)[0];
			}

			this.ctx.team = Object.values(this.state.teams)[0];

			if (this.ctx.user.email.includes("@agtuary")) {
			}

			// Disabled for now

			// this.app.buildBackgroundGradient(
			// 	this.ctx.config?.colors || [],
			// 	this.ctx.config.colorsDirection,
			// 	true
			// );
		});
	}

	public bootstrapDataTeam() {
		console.debug("Bootstrapping data", this);
		// TODO: fetch orgs+teams first, then all data for the selected team
		return Promise.all([
			this.refreshMapToken(),
			this.testCache(
				`GetPaddocks_${this.ctx.user.id}`,
				this.hive.paddocks.get,
				{
					id: this.ctx.user.id,
				}
			),
			// this.testCache(
			// 	`GetProjects_${this.ctx.team?.id}`,
			// 	this.hive.projects.get,
			// 	{
			// 		id: this.ctx.team?.id || "",
			// 	}
			// ),
		]).then((x) => {
			this.updatePermissions();
		});
	}

	public async checkLogin(getData = true) {
		const dev = this.getLocalJson("dev") as any;

		if (Object.keys(dev).length > 0) {
			this.landingRoute = dev.route || this.landingRoute;
			environment.bee = dev.url || environment.bee;
			this.url = environment.bee;
		}

		const customerLS = this.getLocalJson<IUser>(this.customerLSKey);
	}

	updatePermissions(override: boolean = false) {
		override = environment.local && false;
		const hasRole = Object.values(this.state.roles).some(
			(x) => this.state.teams[x.team]
		);

		const mainClient = Object.values(this.state.organisations).some(
			(x) =>
				x.id == "og_CPNbND1ER6D6y79adXJuJTA" ||
				x.id == "og_yS8IbgpQvYMiAkFCoURFyDe" ||
				(environment.local && x.id == "og_0")
		);

		// override landing route
		if (hasRole && mainClient) {
			this.landingRoute = ["1", "map"];
		}

		this.app.setFlags(
			{
				hasRole,
				mainClient,
			},
			override
		);
	}

	public refreshMapToken() {
		if (!this.ctx.organisation?.id) {
			return;
		}

		return this._cpost<any>(
			`${this.url}/v1/maps/refresh-token/${this.ctx.organisation.id}`,
			{}
		)
			.then((x) => {
				return x;
			})
			.catch((x) => {});
	}

	public getTotals() {
		if (!this.ctx.organisation || !this.ctx.organisation.id) {
			return;
		}
		return this.http
			.get<IResponse<any>>(
				`${this.url}/v1/maps/totals/${this.ctx.organisation.id}`,
				this.options
			)
			.toPromise()
			.then((x: any) => {
				if (Object.keys(x).length == 0) {
					return;
				}

				// @ts-ignore
				this.totals = x.totals;
				// @ts-ignore
				this.harvest = x.harvest;
				// @ts-ignore
				this.totalsLastUpdated = x.lastUpdated;

				this.totalsHarvest = [];

				// @ts-ignore
				for (const t of x.totals) {
					// @ts-ignore
					const h = x.harvest.filter((h) => h.name == t.name)[0];
					this.totalsHarvest.push({
						name: t.name,
						total: t,
						harvest: h,
					});
				}

				return x;
			})
			.catch((x) => {});
	}

	public getGeojson() {
		if (!this.ctx.organisation || !this.ctx.organisation.id) {
			return;
		}
		return this.http
			.get<IResponse<any>>(
				`${this.url}/v1/maps/geojson/${this.ctx.organisation.id}`,
				this.options
			)
			.toPromise()
			.then((x) => {
				if (Object.keys(x).length == 0) {
					return;
				}
				// @ts-ignore
				this.geojson = x;
				return x;
			})
			.catch((x) => {});
	}

	public getAllRegions(
		region: "lgas" | "broadacrezones" | "states" | "postcodes" | "suburbs"
	) {
		// if (!this.ctx.organisation || !this.ctx.organisation.id) {
		// 	return;
		// }
		return this.http
			.get<IResponse<any>>(
				`${this.url}/v1/geo/${region}/geojson`,
				this.options
			)
			.toPromise()
			.then((x) => {
				if (Object.keys(x).length == 0) {
					return;
				}
				return x;
			})
			.catch((x) => {});
	}

	public getLGAGeojson(region: string) {
		// if (!this.ctx.organisation || !this.ctx.organisation.id) {
		// 	return;
		// }
		return this.http
			.get<IResponse<any>>(
				`${this.url}/v1/geo/${region}/geojson`,
				this.options
			)
			.toPromise()
			.then((x) => {
				if (Object.keys(x).length == 0) {
					return;
				}
				return x;
			})
			.catch((x) => {});
	}

	public getSites() {
		if (!this.ctx.organisation || !this.ctx.organisation.id) {
			return;
		}
		return this.http
			.get<IResponse<any>>(
				`${this.url}/v1/maps/sites/${this.ctx.organisation.id}`,
				this.options
			)
			.toPromise()
			.then((x) => {
				if (Object.keys(x).length == 0) {
					return;
				}
				// @ts-ignore
				this.sites = x;
				return x;
			})
			.catch((x) => {});
	}

	getBlob(e: HTMLImageElement, url: string) {
		return this.http
			.get<IResponse<any>>(url, this.options)
			.toPromise()
			.then((x) => {
				// @ts-ignore
				e.src = x.url as string;

				return;
			})
			.catch((x) => {
				e.src = "";
			});
	}

	getUrl(coords, type) {
		// @ts-ignore
		const url = `${this.url}/v1/maps/tiles/${
			this.ctx.organisation?.id || "og"
		}/${type}/${coords.z}/${coords.x}/${coords.y}`;
		return url;
	}

	isGNC() {
		return window.location.origin.includes("graincorp.agtuary.app");
	}

	reverseGeo(searchAddress: string) {
		return this._cpost(`${this.url}/v1/geo/reverse-geo`, {
			address: searchAddress,
		});
	}
}
