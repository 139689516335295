import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";
import { AppService } from "@src/app/core/services/app.service";
import { BeeService } from "@src/app/core/services/bee/bee.service";
import { PopupService } from "@src/app/core/services/popup.service";
import { PopupOption } from "@src/app/core/models/popup";
import { ConfirmPopupComponent } from "../confirm-popup/confirm-popup.component";
import { CropMapService } from "@src/app/core/services/crop-map/crop-map.service";

@Component({
	selector: "app-paddock-information",
	templateUrl: "./paddock-information.component.html",
	styleUrls: ["./paddock-information.component.scss"],
})
export class PaddockInformationComponent implements OnInit {
	@Input() pasturePaddock;
	@Input() cropPaddock;

	@Output() displayPaddockList = new EventEmitter();

	constructor(
		public map: CropMapService,
		public app: AppService,
		public bee: BeeService,
		public popup: PopupService
	) {}

	ngOnInit(): void {}

	displayList() {
		this.displayPaddockList.emit();
	}

	editPaddockDetails(str) {
		this.map.displayInfo = "";
		this.map.paddockEditMode = true;

		if (str === "crop") {
			this.map.form = "crop";
		} else {
			this.map.form = "pasture";
		}
	}

	deletePaddock(id: string) {
		const dp = this.popup.show<PopupOption, ConfirmPopupComponent>(
			ConfirmPopupComponent,
			(s) => {
				if (s == "confirm") {
					this.bee.hive.paddocks.delete(id);
					this.displayPaddockList.emit();

					if (this.map.visiblePaddocks[id]) {
						this.map.paddockLayers[id].remove();
						this.map.clearMap();
					}

					this.app.popMessage = "delete";
					setTimeout(() => {
						this.app.popMessage = "";
					}, 3000);
				}
			},
			undefined,
			"normal"
		);

		dp.options = ["cancel", "confirm"];
		dp.message =
			"This feature permanently removes information of the paddock from the platform.";
		dp.header = "Delete paddock";
	}
}
