import { Component, OnInit } from "@angular/core";
import { BasePopup, PopupOption } from "@core/models/popup";
import { PopupService } from "@src/app/core/services/popup.service";

@Component({
	selector: "app-confirm-popup",
	templateUrl: "./confirm-popup.component.html",
	styleUrls: ["./confirm-popup.component.scss"],
})
export class ConfirmPopupComponent
	extends BasePopup<PopupOption>
	implements OnInit
{
	options: PopupOption[] = ["cancel", "confirm"];

	header: string = "";
	message: string = "";

	buttons: { [name in PopupOption]: string } = {
		cancel: "Cancel",
		no: "No",
		confirm: "Confirm",
		yes: "Yes",
	};

	onAdd: (data: PopupOption) => void;
	onClose: () => void;

	constructor(private popup: PopupService) {
		super();
	}

	ngOnInit(): void {}

	close() {
		if (this.onClose) {
			this.onClose();
		}
		this.popup.close();
	}

	doOption(o: PopupOption) {
		this.onAdd(o);
		this.close();
	}
}
